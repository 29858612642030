import * as React from 'react'
import classNames from 'classnames/bind'
import { useSwitch } from '@mui/base/SwitchUnstyled'

import styles from './Switch.module.scss'

const cx = classNames.bind(styles)

// TODO fix the type here
export function Switch(props: any) {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props)

  const stateClasses = {
    'Switch-checked': checked,
    'Switch-disabled': disabled,
    'Switch-focusVisible': focusVisible
  }

  return (
    <span className={cx(styles.BasicSwitchRoot, stateClasses)}>
      <span className={cx(styles.BasicSwitchThumb, stateClasses)} />
      <input {...getInputProps()} aria-label="Switch" className={styles.BasicSwitchInput} />
    </span>
  )
}
