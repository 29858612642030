import { LocaleKey } from '@percent/partner-dashboard/i18n/config'

export const formatMonitoringMessage = (
  organisationIsInactive: boolean,
  flaggedMonitoringKeys: string[] | undefined,
  t: (key: LocaleKey) => string
) => {
  if (organisationIsInactive && flaggedMonitoringKeys?.length) {
    return `${t('typography.organisationIsInactiveAndFlagged')} ${flaggedMonitoringKeys
      .map(flag => t(`typography.${flag}` as LocaleKey))
      .join(', ')}`
  }

  if (organisationIsInactive) {
    return t('typography.organisationIsInactive')
  }

  if (flaggedMonitoringKeys?.length) {
    return `${t('typography.flaggedFor')} ${flaggedMonitoringKeys
      .map(flag => t(`typography.${flag}` as LocaleKey))
      .join(', ')}`
  }

  return null
}
