import React from 'react'
import { Redirect, RouteProps, Route } from 'react-router-dom'

import { Loader } from '@percent/partner-dashboard/common/components'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'

export function AuthorisedRoute({ ...props }: RouteProps) {
  const { isAuthorised, isAuthorising, partner, user } = useAuthState()

  if (isAuthorising || (!partner && !user)) {
    return <Loader />
  }

  if (isAuthorised) {
    return <Route {...props} />
  }

  return <Redirect to="/signin" />
}
