import { Button, DialogTitle, Divider, Grid } from '@material-ui/core'
import React from 'react'

import { ReactComponent as CloseLogo } from '@percent/partner-dashboard/common/assets/images/close.svg'
import styles from './DialogContainer.module.scss'
import { DialogHeaderProps } from './Dialog.types'

export function DialogHeader({ headerTitle, onClose }: DialogHeaderProps) {
  return (
    <>
      <Grid item xs={12} id={styles.dialogHeader}>
        <DialogTitle id={styles.dialogTitle}>{headerTitle}</DialogTitle>
        <Button id={styles.btnCloseDialog} onClick={onClose} type="button" data-testid="close-button">
          <CloseLogo />
        </Button>
      </Grid>
      <Divider className={styles.dialogMargin} />
    </>
  )
}
