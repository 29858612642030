import { baseUrl } from '@percent/partner-dashboard/services/apiPaths'
import { PBPListResponse, PBPResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'
import {
  AddWalletBalanceProps,
  ConditionStateAndConditionAccountLimit,
  DonationWallet,
  Employee,
  GetEmployeesListProps,
  UpdateMatchingBudgetProps,
  WorkplaceGivingServiceCapabilities
} from './workplaceGivingService.types'

export const workplaceGivingService = ({ percentClient }: WorkplaceGivingServiceCapabilities) => ({
  getEmployeesList: (params: GetEmployeesListProps) => {
    const { filter, ...otherParams } = params

    return percentClient.get<PBPListResponse<Employee>>(`${baseUrl}partner/giving/employees`, {
      params: {
        ...otherParams,
        ...(filter && { filter })
      }
    })
  },
  addWalletBalance: ({ amount, accountId }: AddWalletBalanceProps) => {
    return percentClient.post<PBPResponse<DonationWallet>>(`v1/accounts/${accountId}/donation-wallet/add`, { amount })
  },
  updateMatchingBudget: ({ accountId, total }: UpdateMatchingBudgetProps) => {
    return percentClient.put<PBPResponse<ConditionStateAndConditionAccountLimit>>(
      `v1/accounts/${accountId}/donation-matching/account-limit`,
      {
        total
      }
    )
  }
})
