import React from 'react'
import { Box, Grid } from '@material-ui/core'

import { KeyListTable } from '@percent/partner-dashboard/app/dashboard/key/KeyListTable'
import styles from './Home.module.scss'

export function Home() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Box bgcolor="background.paper" className={styles.keysContainer}>
          <KeyListTable />
        </Box>
      </Grid>
      <Grid item xs={6} />
    </Grid>
  )
}
