import { apiErrorMessage } from '@percent/partner-dashboard/constants/apiErrorMessage'

export const APIErrorHandler = (error: any) => {
  if (error) {
    switch (error?.code) {
      case 'auth/invalid_credentials_1':
      case 'auth/invalid_credentials_2':
      case 'auth/invalid_credentials_3':
      case 'auth/invalid_credentials_4':
      case 'auth/password_unsafe_1':
      case 'auth/password_unsafe_2':
      case 'accounts/invalid':
      case 'forbidden':
      case 'bad_request':
      case 'auth/link_invalid':
      case 'rate_exceeded':
      case 'auth/two_fa_invalid_recovery_code':
      case 'auth/two_fa_not_enabled':
      case 'auth/must_login_with_sso':
      case 'auth/link_expired':
        return error?.reasons?.[0] || error?.message
      case 'auth/two_fa_authentication_failed':
        return apiErrorMessage.TWO_FA_INVALID_TOKEN
      case 'auth/two_fa_invalid_otp_code':
        return apiErrorMessage.TWO_FA_INVALID_TOKEN_OTP_CODE
      case 'auth/forbidden_password':
        return apiErrorMessage.FORBIDDEN_PASSWORD
      case 'auth/too_many_change_password_requests':
        return apiErrorMessage.TOO_MANY_CHANGE_PASSWORD_REQUESTS
      case 'not_found':
      default:
        return apiErrorMessage.GENERIC_ERROR
    }
  }

  return apiErrorMessage.GENERIC_ERROR
}
