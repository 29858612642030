import { iamServiceCapabilities, getAccountsProps, putRoleProps, deleteAccountProps } from './iam.types'
import { IamGetAccounts, IamGetRoles } from '../types/iamResponses.types'
import { PBPListResponse, PBPResponse } from '../types/pbpResponses.types'
import { apiPaths } from '../apiPaths'

export const iamService = ({ percentClient }: iamServiceCapabilities) => ({
  patchRoles: ({ accountId, roleId }: putRoleProps) =>
    percentClient.patch(`${apiPaths.v1IamAccounts}/${accountId}/role`, { roleId }),
  getRoles: () => percentClient.get<PBPResponse<IamGetRoles>>(apiPaths.v1IamRoles),
  getPermissions: () => percentClient.get(apiPaths.v1IamPermissions),
  putRolesAttach: (params: putRoleProps) => percentClient.put(apiPaths.v1IamAccountsRolesAttach, { params }),
  getAccounts: (params: getAccountsProps) =>
    percentClient.get<PBPListResponse<IamGetAccounts>>(apiPaths.v1IamAccounts, { params }),
  deleteAccount: (params: deleteAccountProps) =>
    percentClient.delete<PBPListResponse<IamGetAccounts>>(apiPaths.v1IamAccounts, { params })
})
