import styles from './FieldError.module.scss'

export function FieldError(
  { error }: { error?: string } = {
    error: ''
  }
) {
  return (
    <small className={styles.fieldError}>
      &nbsp;
      {error}
    </small>
  )
}
