export const useDownloadStringFile = (textToDownload: string, filename: string) => {
  const downloadTxtFile = () => {
    const element = document.createElement('a')
    const file = new Blob([textToDownload], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = filename
    document.body.appendChild(element)
    element.click()
  }

  return downloadTxtFile
}
