import { Icon } from '../icon'
import styled, { css } from 'styled-components'
import { Combobox } from '@headlessui/react'
import { Float } from '@headlessui-float/react'
import { FakeCheckbox } from '../fake-checkbox'
import { SearchFilterListProps } from './search-filter-list.types'
import { SearchInput } from '../search-input'
import { Button } from '../button'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { StyledBadge } from './filter-list'
import { Spinner } from '../spinner'

export const ListboxWrapper = styled.div``

export const StyledButton = styled(Combobox.Button)<{
  onClick: VoidFunction
  children: ReactNode
}>`
  ${({ theme }) => css`
    margin: 0;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: ${theme.sizes.xxs};
    padding: ${theme.sizes.xxs} ${theme.sizes.xs};
    border-radius: ${theme.borderRadii.medium};
    font-size: ${theme.fontSizes.bodySmall};
    font-weight: ${theme.fontWeights.medium};
    white-space: nowrap;
    min-height: 27.5px;

    :active {
      background: ${theme.colors.primary100};
      color: ${theme.colors.primary400};

      > svg {
        > path {
          fill: ${theme.colors.primary400};
        }
      }
    }

    :focus {
      outline: 1px solid ${theme.colors.primary400};
    }

    :hover {
      background: ${theme.colors.gray70};
    }
  `}
`

export const FilterOptions = styled(Combobox.Options)<{
  static: boolean
  children: ReactNode
}>`
  ${({ theme }) => css`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.white};
    gap: ${theme.sizes.xxs};
    padding: ${theme.sizes.xs};
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
    box-sizing: border-box;

    li {
      font-size: ${theme.fontSizes.buttonText};
      font-weight: ${theme.fontWeights.medium};
      border: none;
      background: none;
      padding: ${theme.sizes.xxs} ${theme.sizes.xs};
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: ${theme.sizes.xs};
      cursor: pointer;
      border-radius: ${theme.borderRadii.medium};
      border: 1px solid transparent;

      &:active,
      &[data-headlessui-state='active'],
      &[data-headlessui-state='active selected'] {
        border: 1px solid ${theme.colors.primary400};
        background: ${theme.colors.gray70};
      }

      :hover {
        background: ${theme.colors.gray70};
        border: 1px solid transparent;
      }
    }
  `}
`

const NoResultsContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray600};
    font-size: ${theme.fontSizes.buttonText};
    padding: 1rem;
  `}
`

const SearchContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    margin: 0;
    border-radius: ${theme.borderRadii.medium} ${theme.borderRadii.medium} 0 0;
    border-bottom: 0.0625rem solid ${theme.colors.gray80};
    padding: ${theme.sizes.xs};
  `}
`

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    padding: ${theme.sizes.xs};
    margin: 0;
    border-radius: 0 0 ${theme.borderRadii.medium} ${theme.borderRadii.medium};
    border-top: 0.0625rem solid ${theme.colors.gray80};
  `}
`

const OpenContainer = styled.div`
  ${({ theme }) => css`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    width: fit-content;
    background: ${theme.colors.white};
    gap: ${theme.sizes.xxs};
    margin: 0;
    box-shadow: ${theme.boxShadows.medium};
    max-height: 300px;
    min-width: 400px;
    max-width: 400px;
    overflow-y: auto;
    border-radius: ${theme.borderRadii.medium};
    box-sizing: border-box;
  `}
`
const LoadingContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;
    color: ${theme.colors.primary400};
  `}
`

export const SearchFilterList = ({
  label,
  options,
  selectedValues,
  onChange,
  isLoading = false,
  testId,
  badgeTestId,
  buttonText,
  searchValue,
  onSearchChange,
  handleClearValue,
  onKeyDown,
  onButtonClick,
  placeholder,
  noResultsFoundText = 'No results found',
  noOptionsText = 'Type in the search box above to find what you’re looking for',
  loadingText = 'We’re looking for your results',
  keyName,
  placement,
}: SearchFilterListProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleButtonClick = () => {
    onButtonClick?.()
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mouseup', handleClickOutside)
    document.addEventListener('touchend', handleClickOutside)

    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
      document.removeEventListener('touchend', handleClickOutside)
    }
  }, [])

  return (
    <Combobox value={selectedValues} onChange={onChange} multiple>
      <ListboxWrapper data-testid={testId} ref={ref}>
        <Float
          show={isOpen}
          placement={placement}
          strategy="absolute"
          offset={8}
        >
          <StyledButton onClick={() => setIsOpen(!isOpen)}>
            <span>{label}</span>
            {selectedValues.length > 0 && (
              <StyledBadge data-testid={badgeTestId || 'filter-list-badge'}>
                {selectedValues.length}
              </StyledBadge>
            )}
            <Icon
              name={isOpen ? 'chevron-up' : 'chevron-down'}
              size={3}
              color="black"
            />
          </StyledButton>
          <OpenContainer>
            <SearchContainer>
              <SearchInput
                placeholder={placeholder}
                value={searchValue}
                onChange={onSearchChange}
                onKeyDown={onKeyDown}
                handleClearValue={handleClearValue}
              />
            </SearchContainer>
            <FilterOptions static>
              {isLoading && (
                <LoadingContainer data-testid="loading">
                  <Spinner size="m" />
                  <span>{loadingText}</span>
                </LoadingContainer>
              )}
              {!isLoading &&
                !!options.length &&
                options.map(({ label, value }) => (
                  <Combobox.Option
                    key={keyName ? value[keyName] : value}
                    value={value}
                  >
                    <FakeCheckbox
                      isSelected={selectedValues.includes(value)}
                      selectedTestId={`${value}-fake-checkbox-selected`}
                    />
                    <span>{label}</span>
                  </Combobox.Option>
                ))}
              {!isLoading && searchValue && !options.length && (
                <NoResultsContainer>{noResultsFoundText}</NoResultsContainer>
              )}
              {!isLoading && !searchValue && !options.length && (
                <NoResultsContainer>{noOptionsText}</NoResultsContainer>
              )}
            </FilterOptions>

            {!!options.length && (
              <ButtonContainer>
                <Button
                  onPress={handleButtonClick}
                  disabled={!selectedValues.length}
                >
                  {buttonText}
                </Button>
              </ButtonContainer>
            )}
          </OpenContainer>
        </Float>
      </ListboxWrapper>
    </Combobox>
  )
}
