import Fuse from 'fuse.js'

import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { VerificationsProps } from '../VerificationsTable.types'
import { ChangeEvent, EventHandler, useCallback, useEffect, useMemo, useState, KeyboardEvent } from 'react'
import { Alpha3Code } from 'i18n-iso-countries'

export const useCountryFilter = ({
  queryParams,
  setQueryParams
}: Pick<VerificationsProps, 'queryParams' | 'setQueryParams'>) => {
  const { countryService } = useServices()
  const [{ data, isLoading }] = useQuery(countryService.getCountries, {})
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedCountries, setSelectedCountries] = useState<Alpha3Code[]>([])
  const [searchContriesResult, setSearchContriesResult] = useState<{ label: string; value: Alpha3Code }[]>([])

  const countryOptions: { label: string; value: Alpha3Code }[] = useMemo(
    () => [
      ...(data
        ? data
            .filter(country => !selectedCountries.find(value => country.code === value))
            .map(option => ({
              label: option.name,
              value: option.code
            }))
        : [])
    ],
    [data]
  )

  const options = {
    includeMatches: true,
    ignoreLocation: true,
    keys: [{ name: 'label', weight: 100 }],
    threshold: 0.1
  }

  const countriesSearch = countryOptions && new Fuse(countryOptions, options)

  const onCountryChange = useCallback(
    (values: Alpha3Code[]) => {
      setSelectedCountries(values)
    },
    [setSelectedCountries]
  )

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleClearSearchValue = () => {
    setSearchValue('')
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (event.key === 'Enter') {
      return setSearchValue((event.target as HTMLInputElement).value)
    }

    if (event.key === 'Escape') {
      return setSearchValue('')
    }
  }

  useEffect(() => {
    if (countryOptions) {
      setSearchContriesResult(countryOptions)
    }
  }, [countryOptions])

  useEffect(() => {
    if (searchValue) {
      const results = countriesSearch.search(searchValue)

      setSearchContriesResult([
        ...selectedCountries.map(option => ({
          label: data?.find(({ code }) => code === option)?.name || '',
          value: option
        })),
        ...results.map(({ item }) => item).filter(country => !selectedCountries.find(value => country.value === value))
      ])
    } else {
      setSearchContriesResult(countryOptions)
    }
  }, [searchValue])

  const handleCountrySearchButtonClick = useCallback(() => {
    setQueryParams({
      ...queryParams,
      ...(selectedCountries.length ? { countryCodes: selectedCountries } : { countryCodes: [] })
    })
  }, [queryParams, selectedCountries, setQueryParams])

  const queryParamFilteredCountries = countryOptions
    .filter(country => queryParams?.countryCodes?.includes(country.value))
    .map(({ value }) => value)

  useEffect(() => {
    if (countryOptions.length && !selectedCountries.length) {
      setSelectedCountries(queryParamFilteredCountries)
    }
  }, [countryOptions])

  useEffect(() => {
    setSelectedCountries(queryParamFilteredCountries)
  }, [queryParams])

  return {
    countryOptions: searchContriesResult,
    isCountryFilterLoading: isLoading,
    selectedCountries,
    onCountryChange,
    onCountrySearchChange: handleSearchChange,
    onClearCountrySearchValue: handleClearSearchValue,
    countrySearchValue: searchValue,
    onCountrySearchButtonClick: handleCountrySearchButtonClick,
    handleKeyPress
  }
}
