import React, { useEffect, useReducer } from 'react'

import { initialState, iamReducer } from '../iamReducer/iamReducer'
import { IamDispatchContext, IamStateContext } from './IamContext'
import { useServices } from '../../serviceContext/ServiceContext'
import { useAuthState } from '@percent/partner-dashboard/common/hooks'
import { SET_PERMISSIONS } from '../iamReducer/iamReducer'
import { AbilityList } from './IamContext.types'

type ContextControllerProps = {
  children: React.ReactNode
  rulesList: AbilityList
}

export function IamContextController({ children, rulesList }: ContextControllerProps) {
  const [state, dispatch] = useReducer(iamReducer, initialState)
  const { isAuthorised, isAuthorising } = useAuthState()
  const { iamService } = useServices()

  useEffect(() => {
    const getPermissionsData = async () => {
      try {
        const {
          data: { data }
        } = await iamService.getPermissions()

        return dispatch({
          type: SET_PERMISSIONS,
          payload: {
            permissions: data,
            rulesList: rulesList
          }
        })
      } catch (err) {
        console.error(err)
      }
    }

    if (isAuthorised && !isAuthorising) {
      getPermissionsData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAuthorised, isAuthorising])

  return (
    <IamStateContext.Provider value={state}>
      <IamDispatchContext.Provider value={dispatch}>{children}</IamDispatchContext.Provider>
    </IamStateContext.Provider>
  )
}
