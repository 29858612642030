import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import { LocationState } from '../TwoFactorAuthentication.types'

import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { SetupQRStep } from './SetupQRStep'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'

export function SetupQRStepContainer() {
  const { authService } = useServices()
  const { state } = useLocation<LocationState>()

  const [{ isLoading, data }, { apiFunc: generateQRToken }] = useMutation(authService.generateQRCode)
  useEffect(() => {
    if (!state?.otpData?.otpKey) generateQRToken({ password: state?.password })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SetupQRStep
      isLoading={isLoading}
      token={state?.otpData?.otpKey ?? data?.data?.data?.otpKey}
      tokenUri={state?.otpData?.otpKeyUri ?? data?.data?.data?.otpKeyUri}
    />
  )
}
