import { Table as MantineTable } from '@mantine/core'
import { ReactNode } from 'react'
import { ColumnProps } from '../table.types'

export function TableBody({ children }: { children: ReactNode }) {
  return <MantineTable.Tbody>{children}</MantineTable.Tbody>
}

export function TableRow({ children }: { children: ReactNode }) {
  return <MantineTable.Tr>{children}</MantineTable.Tr>
}

export function TableCell({
  children,
  inlineStyle,
}: {
  children: ReactNode
  inlineStyle?: ColumnProps
}) {
  return <MantineTable.Td style={inlineStyle}>{children}</MantineTable.Td>
}
