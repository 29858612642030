import React from 'react'

import { useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { NonprofitsTable } from './NonprofitsTable/NonprofitsTable'

export function NonprofitsContainer() {
  const { nonprofitsService } = useServices()
  const [{ data, isLoading, totalResults, errorMessage }, { query, nextPage, previousPage }] = useQueryList(
    nonprofitsService.getNonProfits,
    {}
  )

  return (
    <NonprofitsTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      query={query}
    />
  )
}
