import { Button, CardDashboard, CardContent, Gallery } from '@percent/lemonade'
import { VerificationDocumentsCardProps } from './VerificationDocumentsCard.types'
import ImageGallery from 'react-image-gallery'

import { useTranslation } from 'react-i18next'
import { createRef } from 'react'

export const VerificationDocumentsCard = ({ documents, isLoading }: VerificationDocumentsCardProps) => {
  const galleryRef = createRef<ImageGallery>()
  const { t } = useTranslation()

  return (
    <CardDashboard
      title={t('typography.documentation')}
      data-testid="verification-documents"
      action={
        documents.length > 0 && (
          <Button onPress={() => galleryRef.current?.fullScreen()} size="small">
            {t('button.seeFullscreen')}
          </Button>
        )
      }
    >
      {documents?.length === 0 && <CardContent>{t('typography.noDocumentsOrganisation')}</CardContent>}
      {Array.isArray(documents) && documents?.length > 0 && (
        <Gallery
          galleryRef={galleryRef}
          images={documents}
          isLoading={isLoading}
          testId="verification-documents-gallery"
        />
      )}
    </CardDashboard>
  )
}
