import NoResultQueryImage from '@percent/partner-dashboard/common/assets/images/noResultTableQuery.png'
import NoResultTickImage from '@percent/partner-dashboard/common/assets/images/noResultTableTick.png'
import { NoResultTableImageProps } from './NoResultTable.types'

export const noResultTableImage = (imageType: NoResultTableImageProps) => {
  switch (imageType) {
    case 'query':
      return NoResultQueryImage
    case 'tick':
      return NoResultTickImage
    default:
      return NoResultQueryImage
  }
}
