import React from 'react'
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Tabs,
  Tab,
  Toolbar,
  Grid,
  Tooltip
} from '@material-ui/core'

import { NoResultTableImageProps } from '../noResultTable/NoResultTable.types'

import { PaginationActions, Loader, TableHeadSorter, NoResultTable } from '@percent/partner-dashboard/common/components'
import { TableProps } from './Table.types'
import styles from './Table.module.scss'
import { kebabCase } from '@percent/partner-dashboard/common/library/utility/utility'
import { Alert } from '@percent/lemonade'

export function Table({
  isLoading,
  totalResults,
  previousPage,
  nextPage,
  value,
  columns,
  handleChange,
  handleRequestSort,
  order,
  orderBy,
  tabs,
  title,
  children,
  emptyTableText,
  emptyTableChildren,
  className,
  emptyTableImageType,
  filtersContent,
  toolTipTabs,
  alertMessage,
  'data-testid': dataTestId
}: TableProps) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TableContainer component={Paper} className={`${className} ${styles.tableContainer}`} data-testid={dataTestId}>
          {title && <Toolbar className={styles.toolbar}>{title}</Toolbar>}
          {tabs && (
            <>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                className={styles.tabs}
              >
                {tabs.map((tab: string, index: number) => (
                  <Tab
                    key={tab}
                    label={
                      <Tooltip
                        title={(toolTipTabs && toolTipTabs[index]) || ''}
                        aria-label="add"
                        data-testid="popover"
                        placement="bottom-start"
                      >
                        <span>{tab}</span>
                      </Tooltip>
                    }
                    disableRipple
                    data-testid={kebabCase(tab)}
                  />
                ))}
              </Tabs>
              <div className={styles.indicator} />
            </>
          )}
          {filtersContent}
          {alertMessage && (
            <div className={styles.tableAlertMessage} data-testid="table-alert-message">
              <Alert variant="info">{alertMessage}</Alert>
            </div>
          )}
          <MuiTable className={styles.table}>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : totalResults === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <NoResultTable
                      text={emptyTableText}
                      imageType={emptyTableImageType as NoResultTableImageProps}
                      actionsRow={emptyTableChildren}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <TableHeadSorter
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  cells={columns}
                  testId={dataTestId && `${dataTestId}-header`}
                />
                <TableBody>{children}</TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell className={styles.footerCell} colSpan={10}>
                      <PaginationActions count={totalResults} nextPage={nextPage} previousPage={previousPage} />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </>
            )}
          </MuiTable>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
