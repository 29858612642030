import { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Typography } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'

import { LocationState } from '../TwoFactorAuthentication.types'

import { useTranslation } from 'react-i18next'
import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import { FieldError, Loader } from '@percent/partner-dashboard/common/components'
import styles from './SignInWithRecoveryCode.module.scss'
import { SignInWithConfirmationCodeProps } from './SignInWithRecoveryCode.types'
import { SaveBackupCode } from './SaveBackupCodeStep/SaveBackupCode'
import { AuthForm } from '@percent/partner-dashboard/app/auth/AuthForm'
import { recoveryCodeRegExp } from '@percent/partner-dashboard/common/library/utility/validation'

export function SignInWithRecoveryCode({
  errorMessage,
  isLoading,
  signInFunc,
  isRecoveryCodeConfirmed,
  otpCode,
  setErrorMessage,
  signInToApp
}: SignInWithConfirmationCodeProps) {
  const { state } = useLocation<LocationState>()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      backupCode: ''
    },
    validationSchema: () =>
      yup.object().shape({
        backupCode: yup
          .string()
          .required('Required')
          .matches(recoveryCodeRegExp, t('errorMessage.invalidRecoveryCodeFormat'))
      }),
    onSubmit: ({ backupCode }: { backupCode: string }) => {
      signInFunc({
        backupCode
      })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  useEffect(() => {
    if (values.backupCode) {
      setErrorMessage('')
    }
  }, [values.backupCode, setErrorMessage])

  return isRecoveryCodeConfirmed ? (
    <SaveBackupCode otpCode={otpCode} signInToApp={signInToApp} />
  ) : (
    <AuthForm heading="Two-step verification" handleSubmit={handleSubmit}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Typography className={styles.passwordText} variant="body1">
            {t('form.enterRecoveryCode')}
          </Typography>
          <FormikProvider value={formik}>
            <FormField
              label={t('form.enterRecoveryCodeLabel')}
              status={touched.backupCode && errors.backupCode ? 'danger' : 'default'}
              statusMessage={errors.backupCode}
              data-testid="backupCode"
            >
              <TextInput
                name="backupCode"
                placeholder={t('form.placeholderRecoveryCode')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.backupCode}
              />
            </FormField>
          </FormikProvider>
          {errorMessage && <FieldError error={errorMessage} />}
          <Spacer size={5} axis="vertical" />
          <Link
            to={{
              pathname: '/signin/2fa',
              state: { ...state }
            }}
            className={styles.defaultLink}
          >
            {t('2fa.useTwoFACode')}
          </Link>
          <Spacer size={12} axis="vertical" />
          <Button type="submit" size="large" stretch data-testid="auth-active-button">
            {t('button.confirm')}
          </Button>
          <Spacer size={6} axis="vertical" />
          <Link to="/signin" className={styles.centeredLink}>
            {t('2fa.signInDifferentUser')}
          </Link>
        </>
      )}
    </AuthForm>
  )
}
