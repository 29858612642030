import { Grid } from '@material-ui/core'
import React from 'react'

import { DonationListTable } from './DonationListTable/DonationListTable'

export function DonationContainer() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DonationListTable />
      </Grid>
    </Grid>
  )
}
