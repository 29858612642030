import { Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import styles from './Footer.module.scss'
import { Spacer } from '@percent/lemonade'

const LEGAL_LINK = 'https://goodstack.org/legal'

export function Footer() {
  const { t } = useTranslation()

  return (
    <footer className={styles.footerContainer}>
      <Typography variant="body1" className={styles.footerText}>
        {t('typography.goodstack')}
        &nbsp;
        <span role="img">©</span>
        &nbsp;
        {new Date().getFullYear()}
        <Spacer size={4} axis="horizontal" />
        <span className={styles.dot}></span>
        <Spacer size={4} axis="horizontal" />
        <span className={styles.legal}>
          <a target="_blank" rel="noreferrer" href={LEGAL_LINK}>
            {t('typography.legal')}
          </a>
        </span>
      </Typography>
    </footer>
  )
}
