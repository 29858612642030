import { useContext } from 'react'

import { BreadcrumbsContext } from '../BreadcrumbsContext'

export const useBreadcrumbsData = () => {
  const context = useContext(BreadcrumbsContext)

  if (context === undefined) {
    throw new Error('useBreadcrumbsData must be used within an BreadcrumbsContextController')
  }

  return context
}
