import React from 'react'
import ReactJson from 'react-json-view'
import { DialogTitle, Divider, Grid, Drawer } from '@material-ui/core'

import { PercentButton } from '../buttons/PercentButton/PercentButton'

import { ReactComponent as CloseIcon } from '@percent/partner-dashboard/common/assets/images/close.svg'
import { ReactComponent as ViewRawIcon } from '@percent/partner-dashboard/common/assets/images/view-raw-icon.svg'
import styles from './JsonViewToggle.module.scss'
import { useTranslation } from 'react-i18next'
import { Anchor, CopyProps, JsonViewToggleProps } from './JsonViewToggle.types'

const handleCopy = (copy: CopyProps) => {
  navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'))
}

export function JsonViewToggle({ rawJsonData }: JsonViewToggleProps) {
  const [state, setState] = React.useState({
    right: false
  })
  const { t } = useTranslation()

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      !(
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      )
    ) {
      setState({ ...state, [anchor]: open })
    }
  }

  return (
    <>
      <PercentButton
        startIcon={<ViewRawIcon />}
        className={styles.btnViewRawBtn}
        title="View Raw"
        onClick={toggleDrawer('right', true)}
        testId="view-raw-btn"
      />
      <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)} className={styles.drawer}>
        <Grid item xs={12} className={styles.drawerHeader}>
          <DialogTitle className={styles.dialogTitle}>{t('typography.title')}</DialogTitle>
          <PercentButton
            className={styles.btnCloseViewRaw}
            onClick={toggleDrawer('right', false)}
            type="button"
            data-testid="close-button"
            startIcon={<CloseIcon />}
          />
        </Grid>
        <Divider />
        <ReactJson
          style={{
            paddingTop: '20px',
            wordBreak: 'break-all',
            fontSize: '14px'
          }}
          name={false}
          src={rawJsonData}
          theme="summerfruit"
          displayDataTypes={false}
          enableClipboard={handleCopy}
        />
      </Drawer>
    </>
  )
}
