import React, { useReducer, useEffect, useState } from 'react'

import { initialState, initializer, authReducer, SET_AUTHORISED, SET_UNAUTHORISED } from '../authReducer/authReducer'
import { AuthDispatchContext, AuthStateContext } from '../authContext/AuthContext'

import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { AuthContextControllerProps } from './AuthContextController.types'
import { CurrencyInfo } from '@percent/partner-dashboard/services/donation/donationService'

export function AuthContextController({ children }: AuthContextControllerProps) {
  const [state, dispatch] = useReducer(authReducer, initialState, initializer)
  const [currencyInfoState, setCurrecnyInfoState] = useState<CurrencyInfo[] | undefined>(undefined)

  const { authService, accountService, donationService } = useServices()

  useEffect(() => {
    const getUserData = async () => {
      try {
        const {
          data: { data }
        } = await accountService.getAccount()
        let partner = {}

        if (data?.partnerId) {
          const { data: partnerData } = await authService.getPartner()
          partner = partnerData.data
        }

        const {
          data: { data: currencyInfoData }
        } = await donationService.getCurrencyInfo()
        setCurrecnyInfoState(currencyInfoData)

        return dispatch({
          type: SET_AUTHORISED,
          payload: {
            user: data,
            partner
          }
        })
      } catch (err) {
        return dispatch({
          type: SET_UNAUTHORISED
        })
      }
    }

    if (state.isAuthorised && !state.isAuthorising) {
      getUserData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.isAuthorised, state.isAuthorising, authService, accountService])

  return (
    <AuthStateContext.Provider
      value={{
        ...state,
        currencyInfo: currencyInfoState
      }}
    >
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}
