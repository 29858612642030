import React from 'react'
import styled from 'styled-components'
import { CardDashboardProps } from './card-dashboard.types'
import { Heading } from '../heading'

export const StyledCard = styled.div`
  ${({ theme }) =>
    `
      display: block;
      border-radius: ${theme.borderRadii.large};
      background-color: ${theme.colors.white};
      font-size: ${theme.fontSizes.bodySmall};
      font-weight: ${theme.fontWeights.medium};
      padding: ${theme.sizes['m']};
    `}
`

export const HeaderStyledCard = styled.div`
  ${({ theme }) => `
    align-items: start;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.sizes['s']};
    height:${theme.sizes['xl']};
  `}
`

export const CardDashboard = ({
  action,
  title,
  children,
  ...props
}: CardDashboardProps) => (
  <StyledCard {...props}>
    {(title || action) && (
      <HeaderStyledCard>
        {title && (
          <Heading level="h6" data-testid="card-dashboard-heading">
            {title}
          </Heading>
        )}
        {action}
      </HeaderStyledCard>
    )}

    {children}
  </StyledCard>
)
