import React, { useState } from 'react'
import { TableCell, TableRow } from '@material-ui/core'

import { ErrorView, Table } from '@percent/partner-dashboard/common/components'
import styles from './NonprofitsTable.module.scss'
import { useAuthState } from '@percent/partner-dashboard/common/hooks'
import { NonprofitsListTypes, NonprofitsTableProps } from './NonprofitsTable.types'
import defaultLogo from '@percent/partner-dashboard/common/assets/images/default-img.png'
import { formatAmount, formatMinorUnitsValueToFullNumberWithDecimals } from '@percent/utility'
import { Avatar } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'

const getSortParam = (property: string) => {
  switch (property) {
    case 'raised':
      return 'amount'
    case 'name':
      return 'name'
    default:
      return ''
  }
}

export function NonprofitsTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage,
  query
}: NonprofitsTableProps) {
  const { t } = useTranslation()

  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<string>('')

  const { currencyInfo } = useAuthState()

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)

    const newQueryParams = {
      direction: isAsc ? 'DESC' : 'ASC',
      sort: getSortParam(property)
    }
    query(newQueryParams)
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  const columns = [
    { id: 'header.name', isSortable: true },
    { id: 'header.raised', isSortable: true, props: { align: 'right' } }
  ]

  return (
    <Table
      data={data}
      columns={columns}
      title={t('menu.nonprofits')}
      filtersContent={undefined}
      handleRequestSort={handleRequestSort}
      orderBy={orderBy}
      order={order}
      isLoading={isLoading}
      nextPage={nextPage}
      previousPage={previousPage}
      totalResults={totalResults}
      emptyTableText={t('typography.noRequestsTable')}
    >
      {data?.map(({ id, logo, name, amount, currencyCode }: NonprofitsListTypes) => (
        <TableRow key={id}>
          <TableCell data-testid="nonprofits-name" className={styles.logoName}>
            <Avatar image={logo || defaultLogo} size="medium" alt={`${name} logo`} />
            {name}
          </TableCell>
          <TableCell data-testid="nonprofits-amount" align="right">
            {currencyCode &&
              formatAmount({
                currencyCode,
                value: formatMinorUnitsValueToFullNumberWithDecimals(
                  amount,
                  currencyInfo?.find(el => el.code === currencyCode)?.minorUnits as number
                )
              })}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
