import { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { APIErrorHandler } from '@percent/partner-dashboard/common/library/APIErrorHandler'
import { AxiosResponse } from 'axios'
import { PBPGetResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'

type InferData<A> = A extends AxiosResponse<infer B> ? (B extends PBPGetResponse<infer C> ? C : any) : any

export const useQuery = <A = any, B = any>(
  api: (_: A) => Promise<B>,
  initialQuery: A,
  mount = true,
  initialLoading = true
) => {
  const [body, setBody] = useState<any>(null)
  const data = body?.data || null
  const [isLoading, setIsLoading] = useState(initialLoading)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [status, setStatus] = useState(null)
  const [queryParams, setQueryParams] = useState(initialQuery)
  const [onMount, setOnMount] = useState(mount)

  const query = useCallback(newQueryParams => {
    setOnMount(true)
    setQueryParams(newQueryParams)
  }, [])

  const refresh = useCallback(() => {
    setQueryParams({ ...queryParams })
  }, [queryParams])

  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      let didCancel = false
      setIsLoading(true)
      try {
        const { data: responseBody, status: apiStatus } = (await api(queryParams)) as any

        if (!didCancel) {
          if (apiStatus === 204 || !responseBody) {
            setErrorMessage(t('errorMessage.noResult'))
          }
          setBody(responseBody)
          setStatus(apiStatus)
          setIsLoading(false)
        }
      } catch (err) {
        if (!didCancel) {
          setErrorMessage(APIErrorHandler(err?.response?.data?.error))
          setError(err?.response?.data?.error)
          setIsLoading(false)
        }
      }

      return () => {
        didCancel = true
      }
    }

    if (onMount) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, queryParams])

  return [
    {
      body,
      data: data as InferData<B>,
      isLoading,
      errorMessage,
      error,
      status
    },
    {
      refresh,
      query
    }
  ] as const
}
