import { AxiosInstance } from 'axios'

import { baseUrl } from '@percent/partner-dashboard/services/apiPaths'
import { PBPListResponse, PBPResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'
import { GetNonprofitsProps, NonprofitsType, Organisation } from './nonprofitsService.types'

type ValidationsServiceCapabilities = {
  percentClient: AxiosInstance
}

export const nonprofitsService = ({ percentClient }: ValidationsServiceCapabilities) => ({
  getNonProfits: (params: GetNonprofitsProps) =>
    percentClient.get<PBPListResponse<NonprofitsType>>(`${baseUrl}organisations-summary`, { params }),
  getNonprofit: ({ id }: { id: string | null }) =>
    percentClient.get<PBPResponse<Organisation>>(`${baseUrl}organisations/${id}`)
})
