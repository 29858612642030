import { StatusType } from '@percent/partner-dashboard/constants/status/statusType'
import { AgentVerificationStatus } from '@percent/partner-dashboard/services/applications/applicationsService.types'

export const mapAgentVerificationStatus = (
  agentVerificationStatus: AgentVerificationStatus
): StatusType.Approved | StatusType.Rejected | StatusType.Pending => {
  if (agentVerificationStatus === 'approved') {
    return StatusType.Approved
  }

  if (agentVerificationStatus === 'rejected') {
    return StatusType.Rejected
  }

  return StatusType.Pending
}
