import { OrganisationTypes } from '../../types/application.types'
import { Alpha3Code } from 'i18n-iso-countries'

export const mockValidationData = {
  acceptedAt: null,
  addressLine1: 'ABC Address Line 1',
  addressLine2: 'DEF Address Line 2',
  city: 'Citycity',
  countryCode: 'POL' as Alpha3Code,
  createdAt: '2021-06-18T06:22:35.994Z',
  deletedAt: null,
  documents: [],
  email: 'natalia@poweredbypercent.com',
  id: 'staging_validationrequest_000000CABd89koEdSt9mRe0Dl5DuK',
  name: 'Natalia smoke test Fri 18 Jun 2021 8:26',
  organisationId: 'staging_organisation_000000CACn6YJTCADegGGPqlHCraa',
  rejectionReasonCode: null,
  postal: '44-100',
  registryId: 'velit nisi',
  registryName: 'Duis',
  rejectedAt: null,
  state: 'Silesia',
  website: 'https://percent.internal',
  organisationTypes: [OrganisationTypes.Nonprofit, OrganisationTypes.SocialImpact]
}
