import { Footer } from '@percent/partner-dashboard/common/components'
import { AuthProps } from './Auth.types'
import { CentralCardLayout } from '@percent/domain/iam'

export function AuthForm({
  heading,
  children,
  handleSubmit,
  customHeaderImage,
  showBackgroundImages = false
}: AuthProps) {
  return (
    <CentralCardLayout
      CustomHeaderImage={customHeaderImage}
      heading={heading}
      handleSubmit={handleSubmit}
      backgroundImages={showBackgroundImages}
      footer={<Footer />}
    >
      <div style={{ textAlign: 'left' }}>{children}</div>
    </CentralCardLayout>
  )
}
