import { Button } from '@material-ui/core'
import React from 'react'
import cx from 'classnames'

import styles from './PercentButton.module.scss'
import { PercentButtonProps } from './PercentButton.type'

export function PercentButton({
  alignment = 'alignCentre',
  className = '',
  colour = 'primary',
  disabled,
  fullWidth = false,
  outlined = false,
  onClick,
  testId = '',
  textColour = 'primaryTextColour',
  title = '',
  type = 'button',
  startIcon,
  endIcon,
  underline = false,
  variant = 'contained'
}: PercentButtonProps) {
  const classNames = cx(className, styles[alignment], styles[textColour], {
    [styles.fullWidth]: fullWidth ? 'fullWidth' : '',
    [styles.outlined]: outlined ? 'outlined' : '',
    [styles.underline]: underline ? 'underline' : ''
  })

  return (
    <Button
      color={colour}
      data-testid={testId}
      disabled={disabled}
      disableElevation
      className={classNames}
      onClick={onClick}
      type={type}
      startIcon={startIcon}
      variant={variant}
      endIcon={endIcon}
    >
      {title}
    </Button>
  )
}
