/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import { Menu, MenuItem, TableCell, TableRow, Typography } from '@material-ui/core'
import { Badge, Button, Tooltip } from '@percent/lemonade'
import { useAuthState, useFeatureFlag, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { Table, Loader } from '@percent/partner-dashboard/common/components'
import { RemoveUserModal } from './modals/RemoveUserModal'
import { InviteUserModal } from './modals/InviteUserModal'
import { EditUserModal } from './modals/EditUserModal'
import styles from './UsersListTable.module.scss'
import { EditUserModalProps } from './modals/EditUserModal.types'
import { RemoveUserModalProps } from './modals/RemoveUserModal.types'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { DefaultRoleId } from '@percent/partner-dashboard/context/iam/abilityDefinitions'
import { useTranslation } from 'react-i18next'

export function UsersListTable() {
  const { t } = useTranslation()
  const { iamService } = useServices()
  const { user: currentUser } = useAuthState()
  const { userCan } = usePermissionCheck()
  const { userRolesInviteButtonFeatureFlag } = useFeatureFlag()
  const [isOpenInviteUserModal, setIsOpenInviteUserModal] = useState<boolean>(false)
  const [editModalData, setEditModalData] = useState<EditUserModalProps['openModalData']>(undefined)
  const [removeModalData, setRemoveModalData] = useState<RemoveUserModalProps['openModalData']>(undefined)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [isSelectOpen, setIsSelectOpen] = useState<string | null>(null)

  const [{ data, totalResults, isLoading }, { nextPage, previousPage, refresh: refreshAccounts }] = useQueryList(
    iamService.getAccounts
  )

  const columns = [
    { id: 'header.email', isSortable: false },
    { id: 'header.role', isSortable: false },
    { id: 'header.2fa', isSortable: false }
  ]

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget)
    setIsSelectOpen(id)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setIsSelectOpen(null)
  }

  const isEditable = (idOfEditedUser: string, roleIdOfEditedUser: DefaultRoleId) => {
    if (!currentUser || currentUser.id === idOfEditedUser) {
      return false
    }

    switch (roleIdOfEditedUser) {
      case 'pd:owner':
        return userCan('manageOwners')
      case 'pd:admin':
        return userCan('manageAdmins')
      case 'pd:developer':
        return userCan('manageDevelopers')
      case 'pd:member':
        return userCan('manageMembers')
      default:
        // For unknown roles, we allow admins to edit.
        return userCan('manageAdmins')
    }
  }

  if (isLoading) {
    return <Loader />
  }

  const mapTooltip = (name: string) => {
    const tooltip = {
      Owner: t('toolTip.myTeam.owner'),
      Admin: t('toolTip.myTeam.admin'),
      Developer: t('toolTip.myTeam.developer'),
      Member: t('toolTip.myTeam.member')
    }
    return tooltip[name]
  }

  return (
    <>
      <Table
        data={data}
        title={
          <div className={styles.actionWrapper}>
            {t('menu.users')}
            {userRolesInviteButtonFeatureFlag && (
              <Button size="small" startIcon="add" onPress={() => setIsOpenInviteUserModal(true)}>
                {t('button.invite')}
              </Button>
            )}
          </div>
        }
        isLoading={isLoading}
        totalResults={totalResults || 0}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={columns}
        emptyTableText={t('table.noUsers')}
        emptyTableImageType="query"
        handleRequestSort={() => {
          // do nothing
        }}
        orderBy=""
      >
        {data?.map(({ id, email, role, mfaEnabled, status }, index) => (
          <TableRow key={`permissions-list-${id}`}>
            <TableCell>
              <div className={styles.cellWrapper}>
                <span className={status === 'PENDING' ? styles.disabled : undefined}>{email}</span>
                {status === 'PENDING' && (
                  <Badge variant="default" icon="clock">
                    {t('table.pending')}
                  </Badge>
                )}
              </div>
            </TableCell>
            <TableCell>
              <Tooltip content={mapTooltip(role.name)}>
                {''}
                <Badge variant="default" testId="role-badge">
                  {role.name}
                </Badge>
                {''}
              </Tooltip>
            </TableCell>
            <TableCell>
              {mfaEnabled ? (
                <Badge icon="check-badge" variant="positive">
                  {t('2fa.enable')}
                </Badge>
              ) : (
                <Badge icon="warning" variant="critical">
                  {t('2fa.disable')}
                </Badge>
              )}
            </TableCell>
            <TableCell className={styles.selectCell} data-testid="ellipses-table-cell">
              {isEditable(id, role.id) && (
                <div>
                  <MenuItem onClick={event => handleClick(event, id)} data-testid="user-details-popup">
                    <Typography
                      aria-haspopup="true"
                      onClick={event => handleClick(event, id)}
                      className={styles.ellipsisIcon}
                      data-testid="open-menu-list-composition"
                    >
                      <span className={styles.moreActions} data-testid="edit-user">
                        {t('button.more')}
                      </span>
                    </Typography>
                  </MenuItem>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    // keepMounted
                    open={isSelectOpen === id}
                    onClose={handleClose}
                    className={styles.menu}
                  >
                    <MenuItem
                      className={styles.menuItem}
                      onClick={() => {
                        setEditModalData({ userId: id, email, role: role.name })
                        handleClose()
                      }}
                    >
                      {t('button.edit')}
                    </MenuItem>
                    {/*
                    TODO: Team Iced Latte will add this soon!

                    <MenuItem
                      className={styles.menuItem}
                      onClick={() => {
                        setRemoveModalData({ userId: id, email })
                        handleClose()
                      }}
                    >
                      {formatMessage({ id: 'button.remove' })}
                    </MenuItem>
                    */}
                  </Menu>
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <InviteUserModal
        openModal={isOpenInviteUserModal}
        onClose={() => {
          setIsOpenInviteUserModal(false)
        }}
      />
      <EditUserModal
        refresh={refreshAccounts}
        openModalData={editModalData}
        onClose={() => {
          setEditModalData(undefined)
        }}
      />
      <RemoveUserModal
        refresh={refreshAccounts}
        openModalData={removeModalData}
        onClose={() => {
          setRemoveModalData(undefined)
        }}
      />
    </>
  )
}
