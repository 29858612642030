import { AxiosInstance } from 'axios'

import { getLoginHeadersForApp } from '@percent/domain/iam'
import { apiPaths } from '@percent/partner-dashboard/services/apiPaths'
import { PBPGetResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'
import { AuthServiceGetPartnerResponse, OptKeyConfirmResponse, OtpKeyResponse } from './index.types'

const { v1Auth, v1Account } = apiPaths

type DonationServiceCapabilities = {
  percentClient: AxiosInstance
}

export const authService = ({ percentClient }: DonationServiceCapabilities) => ({
  singIn: (loginDetails: { email: string; password: string; enforce?: boolean }) =>
    percentClient.post(
      `${v1Auth}login`,
      { ...loginDetails },
      {
        headers: getLoginHeadersForApp('partnerDashboard')
      }
    ),
  forgotPassword: (email: string) =>
    percentClient.post(`${v1Auth}forgot-password`, {
      accountType: 'partner',
      email: email.trim()
    }),
  logout: () => percentClient.post(`${v1Auth}logout`),
  setPassword: ({ password, hash }: { password: string; hash: string }) =>
    percentClient.post(`${v1Auth}reset-password`, { password, hash }),
  getAccount: () => percentClient.get(v1Account),
  getPartner: () => percentClient.get<AuthServiceGetPartnerResponse>(`${v1Account}partners`),
  confirmOtpKey: ({ otpCode }: { otpCode: string }) =>
    percentClient.post<PBPGetResponse<OptKeyConfirmResponse>>('v1/account/otp-key-confirm', { otpCode }),
  generateQRCode: ({ password }: { password: string }) =>
    percentClient.post<OtpKeyResponse>('/v1/account/otp-key', { password }),
  verifyOtpKey: ({ token }: { token: string }) => percentClient.post('v1/auth/otp-verify', { token }),
  signInWithRecoveryCode: ({ backupCode }: { backupCode: string }) =>
    percentClient.post('/v1/auth/recovery', { backupCode }),
  disable2fa: ({ password }: { password: string }) =>
    percentClient.post<OtpKeyResponse>('/v1/account/otp-key-remove', { password }),
  sso: (ssoDetails: { email: string; redirectUrl: string }) =>
    percentClient.post(`${v1Auth}sso/login`, { ...ssoDetails })
})
