import { AxiosInstance } from 'axios'

import { apiPaths } from '@percent/partner-dashboard/services/apiPaths'
import { PBPResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'
import { Account, UpdateAccountProps } from './accountService.types'

type accountServiceCapabilities = {
  percentClient: AxiosInstance
}

export const accountService = ({ percentClient }: accountServiceCapabilities) => ({
  getAccount: () => percentClient.get<PBPResponse<Account>>(apiPaths.v1Account),
  getAccountOrganisation: () => percentClient.get(`${apiPaths.v1Account}organisations`),
  updateAccount: (payload: UpdateAccountProps) => percentClient.patch<PBPResponse<Account>>(apiPaths.v1Account, payload)
})
