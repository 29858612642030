import { Dialog, Grid } from '@material-ui/core'
import React from 'react'

import { DialogContainerProps } from './Dialog.types'
import styles from './DialogContainer.module.scss'
import { DialogHeader } from './DialogHeader'

export function DialogContainer({
  openModal,
  onClose,
  headerTitle,
  children,
  fullscreen,
  withoutHeader = false
}: DialogContainerProps) {
  return (
    <Grid container spacing={4}>
      <Dialog
        open={openModal}
        onClose={onClose}
        fullWidth
        maxWidth={fullscreen ? 'lg' : 'sm'}
        className={styles.dialogContainer}
      >
        {!withoutHeader && <DialogHeader onClose={onClose} headerTitle={headerTitle} />}
        {children}
      </Dialog>
    </Grid>
  )
}
