import { baseUrl } from '@percent/partner-dashboard/services/apiPaths'
import { PBPListResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'
import { DonationServiceCapabilities, Donation, GetDonationsListProps } from './donationService.types'

export type CurrencyInfo = {
  code: string
  createdAt: string
  name: string
  minimum: number
  maximum: number
  defaultValue: number
  symbol: string
  encodedSymbol: string
  minorUnits: number
  flagCode: string
  supportedByStripe: boolean
  updateAt: string
}

export const donationService = ({ percentClient }: DonationServiceCapabilities) => ({
  getDonationList: (params: GetDonationsListProps) =>
    percentClient.get<PBPListResponse<Donation>>(`${baseUrl}donations`, { params }),
  getCurrencyInfo: () => percentClient.get<PBPListResponse<CurrencyInfo>>(`${baseUrl}currencies`)
})
