import { ReactElement, useMemo } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { config } from '@percent/partner-dashboard/config'
import { useAuthDispatch } from '@percent/partner-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { SET_LOGOUT, SET_VERIFY } from '@percent/partner-dashboard/context/auth'
import { logger } from '@percent/partner-dashboard/capabilities/logger'

export const percentClient = axios.create({
  baseURL: config.urls.api,
  withCredentials: true
})

export const WithAxios = ({ children }: { children: ReactElement }) => {
  const dispatch = useAuthDispatch()
  const history = useHistory()

  useMemo(() => {
    const errorCodesToLog = [400, 403, 404]

    percentClient.interceptors.response.use(
      responseConfig => responseConfig,
      error => {
        if (error?.response?.status === 401) {
          localStorage.removeItem('authState')
          if (error.response.data.error.code === 'auth/unverified') {
            return dispatch({
              type: SET_VERIFY
            })
          }
          dispatch({
            type: SET_LOGOUT
          })

          return history.push('/expired-session')
        }

        if (error?.response?.status && errorCodesToLog.includes(error?.response?.status)) {
          logger(error)
        }

        throw error
      }
    )
  }, [dispatch, history])

  return children
}
