import React from 'react'
import styled from 'styled-components'
import { ModalHeaderProps } from './modal-header.types'

export const StyledModalHeader = styled.div`
  ${({ theme }) =>
    `
    position: relative;
    display: flex;
    align-items: center;
    gap: ${theme.sizes[3]};

    padding: ${theme.sizes[4]} ${theme.sizes[6]};
    

    ::after {
      content:'';
      position:absolute;
      width: calc(100% - 2 * ${theme.sizes[6]});
      height: 1px;
      background: ${theme.colors.gray80};
      left:0;
      right:0;
      bottom:0;
      margin:auto;
    }
    `}
`

export const ModalHeader = ({ children }: ModalHeaderProps) => {
  return <StyledModalHeader>{children}</StyledModalHeader>
}
