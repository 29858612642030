import React, { forwardRef } from 'react'
import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
} from '@mantine/core'
import styled, { CSSProp, css } from 'styled-components'
import {
  ButtonTextProps,
  ButtonTextVariant,
  ExtendedButtonProps,
} from './button-text-v2.types'
import { Icon } from '../icon'
import { Theme } from '../../theme'
import { styledVariant } from '../../util'

export const StyledButtonLabel = styled.span`
  ${({ theme }) => css`
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: ${theme.sizes[1]};
  `}
`

export const colors: Record<
  ButtonTextVariant,
  (obj: { theme: Theme }) => CSSProp
> = {
  default: ({ theme }) => css`
    color: ${theme.colors.gray600};

    ${StyledButtonLabel} {
      text-decoration-color: ${theme.colors.gray600};
    }

    &:hover {
      color: ${theme.colors.primary400};

      ${StyledButtonLabel} {
        text-decoration-color: ${theme.colors.primary400};
      }
    }

    &:focus {
      &:before {
        box-shadow: 0 0 0 1px ${theme.colors.primary400};
      }

      ${StyledButtonLabel} {
        text-decoration-color: ${theme.colors.primary400};
      }
    }

    &:active {
      color: ${theme.colors.primary400};
    }
  `,
  primary: ({ theme }) => css`
    color: ${theme.colors.primary400};

    ${StyledButtonLabel} {
      text-decoration-color: ${theme.colors.primary400};
    }

    &:hover {
      color: ${theme.colors.primary600};

      ${StyledButtonLabel} {
        text-decoration-color: ${theme.colors.primary600};
      }
    }

    &:focus {
      &:before {
        box-shadow: 0 0 0 1px ${theme.colors.primary600};
      }

      ${StyledButtonLabel} {
        text-decoration-color: ${theme.colors.primary600};
      }
    }

    &:active {
      color: ${theme.colors.primary600};
    }
  `,
}

export const StyledButtonText = styled(MantineButton)<ExtendedButtonProps>`
  ${styledVariant('$variant', colors)};
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 0.125rem;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: 0;
    font-weight: 500;
    font-size: ${theme.fontSizes.buttonText};
    white-space: normal;
    text-align: left;
    line-height: ${theme.sizes[5]};
    position: relative;

    &:disabled {
      pointer-events: none;
      ${StyledButtonLabel} {
        color: ${theme.colors.gray100};
        text-decoration-color: ${theme.colors.gray100};
      }
    }

    &:focus {
      &:before {
        content: '';
        position: absolute;
        left: -4px;
        right: -4px;
        bottom: -4px;
        top: -4px;
        border-radius: ${theme.borderRadii.medium};
      }
    }

    &:active {
      &:before {
        opacity: 0;
      }
    }

    svg {
      min-width: ${theme.sizes[5]};
      min-height: ${theme.sizes[5]};
    }

    & > * {
      line-height: ${theme.sizes[5]};
    }

    &:hover {
      background-color: transparent;
    }
  `}
`

/** 
  ButtonTextV2 is built using the Mantine Button component, which resolves issues
  related to interaction with Menu component when clicking the button.

  TODO: replace ButtonText component
*/
export const ButtonTextV2 = forwardRef<
  HTMLButtonElement,
  ButtonTextProps &
    MantineButtonProps &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ disabled, icon, children, variant = 'default', ...rest }, ref) => {
  return (
    <StyledButtonText
      ref={ref}
      $variant={variant}
      disabled={disabled}
      leftSection={icon && <Icon name={icon} color="inherit" size={5} />}
      {...rest}
    >
      <StyledButtonLabel>{children}</StyledButtonLabel>
    </StyledButtonText>
  )
})
