export enum PartnerScopes {
  Donation = 'donation',
  DonationSession = 'donation_session',
  Organisation = 'organisation',
  DirectDonation = 'direct_donation',
  ValidationRequest = 'validation_request',
  AgentVerification = 'agent_verification',
  ValidationInvite = 'validation_invite',
  SingleValidationSubmissionApi = 'single_validation_submission_api',
  EligibilitySubscription = 'eligibility_subscription',
  MonitoringSubscription = 'monitoring_subscription',
  WorkplaceGiving = 'workplace_giving',
  DonationLink = 'donation_link'
}
