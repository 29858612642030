import { LocaleKey } from '@percent/partner-dashboard/i18n/config'
import { ValidationRequest } from '@percent/partner-dashboard/services/applications/applicationsService.types'

export const formatValidationRequestFailedMessage = ({
  hasNoEligibleOrganisationType,
  validationRequest,
  t
}: {
  hasNoEligibleOrganisationType: boolean
  validationRequest?: ValidationRequest
  t: (descriptor: LocaleKey) => string
}) => {
  if (!validationRequest?.rejectedAt) {
    return null
  }

  if (hasNoEligibleOrganisationType) {
    return t('typography.organisationTypeIsIneligible')
  }

  if (validationRequest?.rejectionReasonCode) {
    return t(`validationRequest.rejectionReasons.${validationRequest?.rejectionReasonCode}`)
  }

  return t('typography.organisationIsNotValidCause')
}
