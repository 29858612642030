import { BadgeVariant, IconRegistry } from '@percent/lemonade'
import { StatusType } from '@percent/partner-dashboard/constants/status/statusType'
import { LocaleKey } from '@percent/partner-dashboard/i18n/config'

export const statusIconMap: {
  [key in StatusType | string]: {
    status: BadgeVariant
    icon: keyof typeof IconRegistry
    text: LocaleKey
  }
} = {
  [StatusType.Pending]: {
    status: 'default',
    icon: 'clock',
    text: 'status.pending'
  },
  [StatusType.Processed]: {
    status: 'default',
    icon: 'clock',
    text: 'status.pending'
  },
  [StatusType.Approved]: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.approved'
  },
  passed: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.passed'
  },
  live: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.live'
  },
  cancelled: {
    status: 'critical',
    icon: 'reject',
    text: 'status.cancelled'
  },
  [StatusType.Rejected]: {
    status: 'critical',
    icon: 'reject',
    text: 'status.rejected'
  },
  [StatusType.CannnotDefine]: {
    status: 'critical',
    icon: 'reject',
    text: 'status.rejected'
  },
  [StatusType.Flag]: {
    status: 'warning',
    icon: 'flag',
    text: 'status.flag'
  },
  [StatusType.EmailSent]: {
    status: 'default',
    icon: 'email',
    text: 'status.emailSent'
  },
  [StatusType.Yes]: {
    status: 'warning',
    icon: 'flag',
    text: 'status.yes'
  },
  [StatusType.No]: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.no'
  },
  [StatusType.Clear]: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.clear'
  },
  [StatusType.Succeeded]: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.approved'
  },
  [StatusType.Failed]: {
    status: 'critical',
    icon: 'reject',
    text: 'status.rejected'
  },
  [StatusType.Active]: {
    status: 'default',
    icon: 'clock',
    text: 'status.active'
  },
  [StatusType.RequestedPayment]: {
    status: 'informative',
    icon: 'info',
    text: 'status.requestedPayment'
  },
  [StatusType.ReceivedPayment]: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.receivedPayment'
  },
  [StatusType.Disbursed]: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.disbursed'
  },
  [StatusType.Cancelled]: {
    status: 'critical',
    icon: 'reject',
    text: 'status.cancelled'
  },
  [StatusType.OrgIsValid]: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.orgIsValid'
  },
  [StatusType.OrgIsNotValid]: {
    status: 'critical',
    icon: 'reject',
    text: 'status.orgIsNotValid'
  }
}
