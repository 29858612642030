import i18n from 'i18next'
import { initReactI18next, DefaultResources } from 'react-i18next'

import { translationResources } from './resources'

i18n.use(initReactI18next).init({
  resources: translationResources,
  lng: 'en-US',
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false
  },
  returnEmptyString: false
})

// eslint-disable-next-line import/no-default-export
export default i18n

export type LocaleKey = keyof DefaultResources['partnerDashboard']
