export enum StatusType {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Flag = 'flag',
  Clear = 'clear',
  Yes = 'yes',
  No = 'no',
  InProgress = 'in progress',
  EmailSent = 'email sent',
  ValidationFailed = 'validation_failed',
  Invalid = 'invalid',
  Verified = 'verified',
  Accepted = 'accepted',
  Failed = 'failed',
  Succeeded = 'succeeded',
  Active = 'ACTIVE',
  RequestedPayment = 'REQUESTED_PAYMENT',
  ReceivedPayment = 'RECEIVED_PAYMENT',
  Disbursed = 'DISBURSED',
  Cancelled = 'CANCELLED',
  Processed = 'processed',
  OrgIsValid = 'Org is valid',
  OrgIsNotValid = 'Org is not valid',
  CannnotDefine = 'cannot_define'
}
