import React, { useState } from 'react'

import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs'
import { WizardProps } from './Wizard.types'
import { WizardContext } from './WizardContext/WizardContext'

export function Wizard({ steps, children }: WizardProps) {
  const [currentStep, setCurrentStep] = useState(0)
  const [isAuthenticationComplete, setIsAuthenticationComplete] = useState(false)

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  return (
    <WizardContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ currentStep, setNextStep: handleNextStep, steps, isAuthenticationComplete, setIsAuthenticationComplete }}
    >
      <Breadcrumbs />
      {!isAuthenticationComplete && steps[currentStep].step}
      {isAuthenticationComplete && children}
    </WizardContext.Provider>
  )
}
