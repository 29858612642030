import { FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { object, string } from 'yup'

import { AuthForm } from '../AuthForm'

import { Button, Feedback, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'
import { config } from '@percent/partner-dashboard/config'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { emailRegex } from '@percent/utility'
import { useTranslation } from 'react-i18next'
import { useQueryParam } from 'use-query-params'
import { Link } from 'react-router-dom'
import styles from './SSO.module.scss'

export function SSO() {
  const { authService } = useServices()
  const { t } = useTranslation()
  const { isAuthorised } = useAuthState()
  const { push } = useHistory()
  const [awaitingRedirect, setAwaitingRedirect] = useState(false)

  const [emailQueryParam, setEmailQueryParam] = useQueryParam('email')
  const [formLoadAction] = useQueryParam('action')
  const [customMessage, setCustomMessage] = useState('')

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: () =>
      object().shape({
        email: string().trim().required('Required').matches(emailRegex, 'Must be a valid email')
      }),
    onSubmit: (ssoDetails: { email: string }) => {
      ssoAuth(ssoDetails)
    }
  })

  useEffect(() => {
    if (isAuthorised) {
      push('/dashboard')
    }

    if (emailQueryParam && !formik.values.email) {
      formik.setFieldValue('email', emailQueryParam)

      if (formLoadAction && formLoadAction === 'sso-redirect') {
        setCustomMessage(t('typography.ssoSignInRequired'))
        setEmailQueryParam(undefined)
      }
    }
  }, [isAuthorised, push, emailQueryParam, formik, formLoadAction, t, setEmailQueryParam])

  const ssoUserAndUpdate = async (ssoDetails: { email: string }) => {
    setAwaitingRedirect(true)
    try {
      const response = await authService.sso({
        email: ssoDetails.email.trim(),
        redirectUrl: config.urls.app
      })

      const { loginUrl } = response.data.data
      setAwaitingRedirect(true)

      window.location.href = loginUrl

      return response
    } catch (error) {
      if (error.response.data.error.code === 'sso/partner_not_configured')
        setCustomMessage(t('errorMessage.noSSOConfiguration'))
      setAwaitingRedirect(false)
    }
    return null
  }

  const [{ errorMessage }, { apiFunc: ssoAuth }] = useMutation(ssoUserAndUpdate)

  useEffect(() => {
    if (errorMessage) {
      setAwaitingRedirect(false)
    }
  }, [errorMessage])

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <AuthForm heading={t('typography.signInSso')} handleSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FormField
          label={t('form.emailAddress')}
          status={touched.email && errors.email ? 'danger' : 'default'}
          statusMessage={errors.email || errorMessage}
          data-testid="email"
        >
          <TextInput
            name="email"
            placeholder={t('form.enterEmailAddress')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
        </FormField>
        <Spacer size={2} axis="vertical" />
        {(errorMessage || customMessage) && <Feedback variant="critical">{errorMessage || customMessage}</Feedback>}
        <Spacer size={5} axis="vertical" />
        <Button type="submit" stretch size="large" data-testid="auth-active-button" loading={awaitingRedirect}>
          {t('button.signInSso')}
        </Button>
        <Spacer size={6} axis="vertical" />
        <Link to="/signin" className={styles.signInWithEmailLink}>
          {t('typography.signInWithEmail')}
        </Link>
      </FormikProvider>
    </AuthForm>
  )
}
