import React from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import { useTranslation } from 'react-i18next'
import { Button, Feedback, FormField, Spacer, TextInput } from '@percent/lemonade'
import styles from './ConfirmationCodeStep.module.scss'
import { ConfirmationCodeViewProps } from './ConfirmationCodeStep.types'
import { AuthForm } from '../../../AuthForm'

export function ConfirmationCodeStep({ errorMessage, isLoading, verifyFunc }: ConfirmationCodeViewProps) {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      confirmationCode: ''
    },
    validationSchema: () =>
      yup.object().shape({
        confirmationCode: yup
          .string()
          .required('Required')
          .matches(/^[0-9]+$/, t('errorMessage.onlyDigits'))
          .min(6, t('errorMessage.max6Digit'))
          .max(6, t('errorMessage.max6Digit'))
      }),
    onSubmit: ({ confirmationCode }: { confirmationCode: string }) => {
      verifyFunc({
        otpCode: confirmationCode
      })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <AuthForm heading={t('2fa.enterConfirmationCode')} handleSubmit={handleSubmit}>
      <p className={styles.headerTwo}>{t('2fa.enterConfirmationCodePart2')}</p>
      <Spacer axis="vertical" size={8} />
      <FormikProvider value={formik}>
        <FormField
          label={t('form.enterSixDigit')}
          status={touched.confirmationCode && errors.confirmationCode ? 'danger' : 'default'}
          statusMessage={errors.confirmationCode || errorMessage}
          data-testid="confirmationCode"
        >
          <TextInput
            name="confirmationCode"
            placeholder={t('form.placeholderSixDigit')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmationCode}
          />
        </FormField>
        <Spacer size={4} axis="vertical" />
        {errorMessage && (
          <>
            <Feedback variant="critical">{errorMessage}</Feedback>
            <Spacer size={4} axis="vertical" />
          </>
        )}
        <Button size="large" type="submit" stretch loading={isLoading} data-testid="verify-button">
          {t('button.verify')}
        </Button>
      </FormikProvider>
    </AuthForm>
  )
}
