import { ReactNode, useContext } from 'react'
import { TableContext, TableContextType } from '../table.types'
import styled from 'styled-components'

export const useTableContext = () => {
  const context = useContext(TableContext)
  if (!context) {
    throw new Error('useTableContext must be used within a TableProvider')
  }
  return context
}

const TableWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes.xs};
    border-radius: 8px;
    overflow: hidden;
    padding: ${theme.sizes.s};
    background-color: white;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-align: left;
  `}
`

export const TableProvider = ({
  children,
  columns,
  totalResults,
  isLoading,
  illustration,
  emptyTableTitle,
  emptyTableDescription,
}: {
  children: ReactNode
} & TableContextType) => {
  const contextValue = {
    columns,
    totalResults,
    isLoading,
    illustration,
    emptyTableTitle,
    emptyTableDescription,
  }

  return (
    <TableContext.Provider value={contextValue}>
      <TableWrapper>{children}</TableWrapper>
    </TableContext.Provider>
  )
}
