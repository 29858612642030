import { useTranslation } from 'react-i18next'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { VerificationsProps } from '../VerificationsTable.types'
import { useCallback } from 'react'

export const useProgramNameFilter = ({
  queryParams,
  setQueryParams
}: Pick<VerificationsProps, 'queryParams' | 'setQueryParams'>) => {
  const { t } = useTranslation()
  const { applicationsService } = useServices()

  const [{ data, isLoading }] = useQuery(applicationsService.getValidationSubmissionConfigurations, {})

  const programNameOptions =
    data?.map(option => ({
      label: option.name || t('typography.unnamedProgram'),
      value: option.id
    })) ?? []

  const onProgramNameChange = useCallback(
    (programs: string[]) => {
      setQueryParams({
        configurationIds: programs
      })
    },
    [setQueryParams]
  )

  return {
    programNameOptions,
    isProgramNameFilterLoading: isLoading,
    selectedProgramNames: queryParams?.configurationIds ?? [],
    onProgramNameChange
  }
}
