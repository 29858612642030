import { useContext } from 'react'

import { WizardContext } from '../WizardContext/WizardContext'

export const useWizard = () => {
  const context = useContext(WizardContext)

  if (context === undefined) {
    throw new Error('useWizard must be used within an Wizard.Provider!')
  }

  return context
}
