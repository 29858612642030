import React from 'react'
import { Typography } from '@material-ui/core'

import styles from './NoResultTable.module.scss'
import { noResultTableImage } from './NoResultTableImage'
import { NoResultTableProps } from './NoResultTable.types'

export function NoResultTable({ text, imageType, actionsRow }: NoResultTableProps) {
  return (
    <div className={styles.noResultTableContainer}>
      <div>
        <img alt="no result" src={noResultTableImage(imageType)} />
        <Typography variant="h6" color="secondary">
          {text}
        </Typography>
        {actionsRow}
      </div>
    </div>
  )
}
