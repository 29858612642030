import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import { object, string } from 'yup'

import { AuthForm } from '../AuthForm'
import { AuthSuccessText } from '../AuthSuccessText'

import { Loader } from '@percent/partner-dashboard/common/components'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import styles from './ForgotPassword.module.scss'
import { Button, Feedback, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { emailRegex } from '@percent/utility'

export function ForgotPassword() {
  const { authService } = useServices()
  const [{ success, errorMessage, isLoading }, { apiFunc: forgotPassword }] = useMutation(authService.forgotPassword)
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: () =>
      object().shape({
        email: string().required('Required').matches(emailRegex, t('errorMessage.invalidEmail'))
      }),
    onSubmit: ({ email }) => {
      forgotPassword(email)
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <AuthForm heading={t('form.forgotPassword')} handleSubmit={handleSubmit}>
      {success ? (
        <AuthSuccessText text={t('typography.passwordReset')} />
      ) : (
        <>
          <p className={styles.resetText}>{t('typography.passwordResetLink')}</p>
          <FormikProvider value={formik}>
            <FormField
              label={t('form.emailAddress')}
              status={touched.email && errors.email ? 'danger' : 'default'}
              statusMessage={errors.email}
              data-testid="email"
            >
              <TextInput
                name="email"
                placeholder={t('form.enterEmailAddress')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </FormField>
          </FormikProvider>
          {errorMessage && <Feedback variant="critical">{errorMessage}</Feedback>}
          <Spacer size={5} axis="vertical" />
          <Button stretch size="large" data-testid="auth-active-button" type="submit" loading={isLoading}>
            Send email
          </Button>
          <p className={styles.clickHere}>
            {t('typography.lookingForSignIn')} <Link to="/signin">{t('typography.clickHere')}</Link>
          </p>
        </>
      )}
    </AuthForm>
  )
}
