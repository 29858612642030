import { Alert, Badge, CardDashboard, CardContent, Spacer } from '@percent/lemonade'
import { VerificationSummaryCardProps } from './VerificationSummaryCard.types'

import { mapOrganisationStatus } from './utils/mapOrganisationStatus'
import { TableCellStatusIcon } from '@percent/partner-dashboard/common/components'
import { mapEligibilityStatus } from './utils/mapEligibilityStatus'
import { mapComplainceStatus } from './utils/mapComplianceStatus'
import { useTranslation } from 'react-i18next'
import { StatusType } from '@percent/partner-dashboard/constants/status/statusType'
import { mapOverallStatus } from './utils/mapOverallStatus'
import { mapAgentVerificationStatus } from './utils/mapAgentVerificationStatus'
import { mapAlertProps } from './utils/mapAlertProps'
import styles from './VerificationSummaryCard.module.scss'
import { formatMonitoringMessage } from './utils/formatMonitoringMessage'
import { useMemo } from 'react'
import { formatStatusBannerMessage } from './utils/formatStatusBannerMessage'
import { formatValidationRequestFailedMessage } from './utils/formatValidationRequestFailedMessage'

export const VerificationSummaryCard = ({
  verification,
  organisation,
  hasNoEligibleOrganisationType
}: VerificationSummaryCardProps) => {
  const { t } = useTranslation()

  // Organisation
  const organisationName = verification.organisationName ?? verification.validationRequest?.name
  const organisationStatus = useMemo(() => mapOrganisationStatus(verification), [verification])

  const validationFailedMessage = useMemo(
    () =>
      formatValidationRequestFailedMessage({
        hasNoEligibleOrganisationType,
        validationRequest: verification?.validationRequest,
        t
      }),
    [hasNoEligibleOrganisationType, verification?.validationRequest, t]
  )

  // Eligibility
  const eligibility = verification.eligibility
  const eligibilityStatus = useMemo(() => eligibility && mapEligibilityStatus(eligibility), [eligibility])
  const rejectedEligibilityTags = eligibility?.results?.rejectedActivitySubTags ?? []

  // Agent
  const agentVerification = verification.agentVerification
  const agentVerificationStatus = useMemo(
    () => agentVerification && mapAgentVerificationStatus(agentVerification?.status),
    [agentVerification]
  )
  const agentEmail = agentVerification?.email

  // Monitoring
  const monitoring = verification.monitoring
  const monitoringStatus = useMemo(() => monitoring && mapComplainceStatus(monitoring), [monitoring])
  const organisationIsInactive = monitoring?.results?.registration?.active === 'no'
  const flaggedMonitoringKeys =
    monitoring?.results &&
    Object.keys(monitoring.results).filter(result => monitoring?.results?.[result]?.status === 'flag')

  const monitoringMessage = useMemo(
    () => formatMonitoringMessage(organisationIsInactive, flaggedMonitoringKeys, t),
    [organisationIsInactive, flaggedMonitoringKeys, t]
  )

  // Overall
  const overallStatus = useMemo(() => mapOverallStatus(verification.status), [verification.status])

  const alertProps = useMemo(() => mapAlertProps(overallStatus), [overallStatus])

  const bannerMessage = useMemo(
    () =>
      formatStatusBannerMessage({
        overallStatus,
        agentEmail,
        configurationName: verification.configuration?.name,
        t
      }),
    [overallStatus, agentEmail, verification.configuration?.name, t]
  )

  return (
    <CardDashboard title={organisationName ?? ''}>
      <Alert variant={alertProps.variant} title={bannerMessage} />

      <Spacer axis="horizontal" size={2} />
      <CardContent title={t('typography.organisation')} testId="verification-summary-organisation">
        <div>
          <TableCellStatusIcon statusType={organisationStatus} />
          {hasNoEligibleOrganisationType &&
            organisation?.types
              .filter(type => !verification.configuration?.allowedOrganisations?.types.includes(type))
              .map(type => (
                <>
                  <Spacer axis="horizontal" size={2} />
                  <Badge key={type} variant="default" icon="categories">
                    {t(`organisationTypes.${type}`)}
                  </Badge>
                </>
              ))}
        </div>
        {organisationStatus === StatusType.Rejected && validationFailedMessage}
      </CardContent>
      {!verification?.validationRequest?.rejectedAt && (
        <>
          {eligibility && (
            <CardContent title={t('typography.activity')} testId="verification-eligibility">
              <div className={styles.rejectedEligibilityTagsWrapper}>
                {eligibilityStatus && <TableCellStatusIcon statusType={eligibilityStatus} />}
                {rejectedEligibilityTags?.map(tag => (
                  <Badge key={tag.id} variant="default">
                    {tag.name}
                  </Badge>
                ))}
              </div>
              {eligibilityStatus === StatusType.Rejected && t('typography.tagsNotEligible')}
              {eligibilityStatus === StatusType.CannnotDefine && t('typography.cannotDefineEligibility')}
            </CardContent>
          )}

          {monitoring && (
            <CardContent title={t('typography.compliance')} testId="verification-compliance">
              {monitoringStatus && (
                <div>
                  <TableCellStatusIcon statusType={monitoringStatus} />
                </div>
              )}
              {monitoringMessage}
            </CardContent>
          )}

          {agentVerification && (
            <CardContent title={t('typography.agentVerification')} testId="verification-agent-verification">
              {agentVerificationStatus && (
                <div>
                  <TableCellStatusIcon statusType={agentVerificationStatus} />
                </div>
              )}
              {agentVerification.rejectionReasonCode &&
                t(`agentVerificationRejectionReasons.${agentVerification.rejectionReasonCode}`)}
            </CardContent>
          )}
        </>
      )}
    </CardDashboard>
  )
}
