import React from 'react'
import styled from 'styled-components'
import { CardContentProps } from './card-content.types'

export const StyledCardContent = styled.div`
  ${({ theme }) =>
    `
      font-size: ${theme.fontSizes.bodySmall};
      display: flex;
      align-items: start;
      margin-bottom: ${theme.sizes['s']};
  
      &:last-of-type {
        margin-bottom: 0px;
      }
    `}
`

export const StyledCardContentTitle = styled.div`
  ${({ theme }) => `
    font-weight: ${theme.fontWeights.medium};
    flex: 1;
    line-height: ${theme.sizes[6]};
  `}
`

export const StyledCardContentBody = styled.div`
  ${({ theme }) => `
    font-weight: ${theme.fontWeights.regular};
    flex: 4;
    color: ${theme.colors.neutral300};
    line-height: ${theme.sizes[6]};

    a {
      text-decoration: underline;
      text-underline-position: under;
      color: ${theme.colors.gray700};
      font-weight: ${theme.fontWeights.regular};
      &:hover {
        color: ${theme.colors.primary400}
      }
    }
  `}
`

export const CardContent = ({
  title,
  children,
  testId = 'card',
}: CardContentProps) => (
  <StyledCardContent>
    {title && (
      <StyledCardContentTitle data-testid={testId + '-title'}>
        {title}
      </StyledCardContentTitle>
    )}
    <StyledCardContentBody data-testid={testId + '-body'}>
      {children}
    </StyledCardContentBody>
  </StyledCardContent>
)
