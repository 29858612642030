import { Action } from './iamReducer.types'
import { IamStateContextType } from '@percent/partner-dashboard/context/iam/iamContext/IamContext.types'
import { abilityDefinitions } from '@percent/partner-dashboard/context/iam/abilityDefinitions'

export const SET_PERMISSIONS = 'iam/set-permissions'

export const initialState = {
  permissions: [],
  abilityList: abilityDefinitions
}

export const iamReducer: (state: IamStateContextType, action: Action) => IamStateContextType = (state, action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        ...initialState,
        ...state,
        ...action.payload
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
