import { useEffect } from 'react'

import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useQuery, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useBreadcrumbsData } from '@percent/partner-dashboard/app/dashboard/layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'
import { VerificationDetails } from './VerificationDetails/VerificationDetails'

export function VerificationDetailsContainer({ match }: Readonly<{ match: { params: { verificationId: string } } }>) {
  const { verificationId } = match.params
  const { applicationsService, nonprofitsService } = useServices()
  const [{ data, isLoading, errorMessage }] = useQuery(applicationsService.getValidationSubmission, {
    id: verificationId
  })
  const [{ data: validationSubmissionDocuments, isLoading: isLoadingValidationSubmissionDocuments }] = useQueryList(
    applicationsService.getValidationSubmissionDocuments,
    {
      id: verificationId
    }
  )

  const [{ data: organisation, isLoading: isLoadingOrganisation }, { query }] = useQuery(
    nonprofitsService.getNonprofit,
    {
      id: data?.organisationId
    },
    !!data?.organisationId,
    false
  )

  const { setPath } = useBreadcrumbsData()

  useEffect(() => {
    setPath(data?.organisationName)
  }, [setPath, data])

  useEffect(() => {
    data?.organisationId &&
      query({
        id: data?.organisationId
      })
  }, [data?.organisationId, query])

  return (
    <VerificationDetails
      data={data}
      organisation={organisation}
      isLoadingOrganisation={isLoadingOrganisation}
      isLoading={isLoading}
      errorMessage={errorMessage}
      documents={validationSubmissionDocuments || []}
      isLoadingValidationSubmissionDocuments={isLoadingValidationSubmissionDocuments}
    />
  )
}
