import styled from 'styled-components'

export const SearchAndFiltersWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.sizes.xs};
  `}
`

export function TableSearchAndFilters({
  children,
}: {
  children: React.ReactNode
}) {
  return <SearchAndFiltersWrapper>{children}</SearchAndFiltersWrapper>
}
