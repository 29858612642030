import { Container, Typography } from '@material-ui/core'

import { PercentLogo } from '../percentLogo/PercentLogo'

import { useTranslation } from 'react-i18next'
import { ReactComponent as LockCircleLogo } from '@percent/partner-dashboard/common/assets/images/lock_circle.svg'
import styles from './NoPrivilegeAccessView.module.scss'

export function NoPrivilegeAccessView({ errorMessage }: { errorMessage?: string }) {
  const { t } = useTranslation()

  return (
    <Container maxWidth="xs" className={styles.container}>
      <PercentLogo className="percentLogo" />
      <LockCircleLogo width="116.25" height="116.25" className={styles.lockLogo} />
      <Typography variant="h4">{t('typography.oops')}</Typography>
      <Typography variant="h6" className={styles.noPrivilegeText}>
        {t('typography.insufficientPermissions')}
      </Typography>
    </Container>
  )
}
