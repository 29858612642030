import React from 'react'

import { useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { CategoryTable } from './CategoryTable/CategoryTable'

export function CategoryContainer() {
  const { categoryService } = useServices()
  const [{ data, isLoading, totalResults, errorMessage }, { query, nextPage, previousPage }] = useQueryList(
    categoryService.getCategoryList,
    {}
  )

  return (
    <CategoryTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      query={query}
    />
  )
}
