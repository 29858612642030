import React, { ReactNode } from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import '../app.scss'

import { MuiTable } from './overrides'
import { drawerWidth } from '@percent/partner-dashboard/app/dashboard/layout/Layout'
export const goodstackPalette = {
  primary30: '#FBF8FF',
  primary100: '#F4EDFF',
  primary400: '#8B4AFF',
  primary600: '#412276',
  secondary100: '#e9fbf9',
  secondary400: '#23d3c1',
  secondary600: '#23c9b8',
  neutral100: '#e5effc',
  neutral300: '#6D6A73',
  neutral400: '#1b034f',
  neutral500: '#56525c',
  neutral600: '#110231',
  info30: '#FBF8FF',
  info100: '#F4EDFF',
  info400: '#8B4AFF',
  warning30: '#FEFBF8',
  warning100: '#FDE3CC',
  warning400: '#E77309',
  success30: '#F7FBFA',
  success100: '#DFF6EC',
  success400: '#008850',
  alert30: '#FEF8F8',
  alert100: '#FCE2E3',
  alert400: '#E71A22',
  white: '#ffffff',
  gray20: '#E1E2E3',
  gray70: '#F5F5F6',
  gray80: '#F1F0F2',
  gray90: '#E9E8EB',
  gray100: '#B6B4B9',
  gray200: '#070C18',
  gray300: '#6D6A73',
  gray400: '#615E67',
  gray500: '#56525C',
  gray600: '#494550',
  gray700: '#3D3944',
  gray800: '#302C38',
  gray900: '#25202D',
  black: '#0D0D0D',
  primary: '#8B3DFF',
  primaryHover: '#412276',
  primaryActive: '#412276',
  primaryFocus: '#412276'
}

const theme = createTheme({
  palette: {
    primary: { main: goodstackPalette.primary400 },
    secondary: { main: goodstackPalette.neutral400 },
    text: {
      primary: goodstackPalette.neutral400
    },
    success: { main: goodstackPalette.success400 }
  },
  overrides: {
    MuiTableContainer: {
      root: {
        width: 'auto',
        padding: '20px 24px'
      }
    },
    MuiSnackbar: {
      anchorOriginTopCenter: {
        top: '50px !important',
        transform: 'translateX(-50%) !important'
      }
    },
    MuiTable,
    MuiTableCell: {
      root: {
        padding: '12px 0',
        borderBottom: 'none',
        fontSize: '14px'
      },
      footer: {
        border: 'none'
      }
    },
    MuiTab: {
      root: {
        '&.Mui-selected': {
          color: `${goodstackPalette.neutral400} !important`
        }
      }
    },
    MuiToolbar: {},
    MuiDrawer: {
      paperAnchorDockedLeft: {
        width: drawerWidth,
        borderRight: 'none',
        borderRadius: '0px !important',
        boxShadow: '0px 2px 16px rgba(5, 0, 56, 0.08) !important'
      }
    },
    MuiAppBar: {
      root: {
        flexDirection: 'row-reverse',
        backgroundColor: `${goodstackPalette.white} !important`,
        boxShadow: '0px 2px 16px rgba(5, 0, 56, 0.08)'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px'
      }
    },
    MuiSelect: {
      root: {
        padding: '14px',
        fontSize: '14px'
      },
      select: {
        '&:focus': {
          backgroundColor: goodstackPalette.white
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: goodstackPalette.neutral400
        }
      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important',
        borderRadius: '8px !important'
      }
    },
    MuiTableRow: {
      root: {
        borderBottom: `1px solid ${goodstackPalette.gray80}`
      },
      footer: {
        borderBottom: 'none'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        backgroundColor: `${goodstackPalette.black}`,
        opacity: '0.9',
        borderRadius: '2px',
        padding: '12px 16px',
        position: 'absolute',
        width: '400px'
      }
    },
    MuiDialog: {
      paperWidthLg: {
        maxWidth: '100% !important',
        maxHeight: '100% !important',
        width: '100% !important',
        height: '100% !important',
        margin: '0 !important'
      }
    }
  },
  typography: {
    fontFamily: ['Inter", "Arial", "Helvetica", "sans-serif"'].join(','),
    h5: {
      fontWeight: 500
    },
    h6: {
      fontSize: 20
    },
    subtitle1: {
      fontSize: 18
    },
    subtitle2: {
      fontSize: 16
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 12
    },
    button: {
      textTransform: 'none'
    }
  }
})

export function Theme({ children }: { children?: ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
