import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import styles from './AuthSuccessText.module.scss'
import { AuthSuccessTextProps } from './Auth.types'
import { Button } from '@percent/lemonade'

export function AuthSuccessText({ text, showSignInLink = true, showSignInButton = false }: AuthSuccessTextProps) {
  const { t } = useTranslation()
  const { push } = useHistory()

  const goToSignIn = () => push('/signin')

  return (
    <>
      <p className={styles.successText}>{text}</p>
      {showSignInLink && (
        <p className={styles.signInLink}>
          <Link to="/signin">{t('typography.nowSignInHere')}</Link>
        </p>
      )}
      {showSignInButton && (
        <div className={styles.signInButtonWrapper}>
          <Button stretch size="large" onPress={goToSignIn}>
            {t('typography.signInHere')}
          </Button>
        </div>
      )}
    </>
  )
}
