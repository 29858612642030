import { TableCell, TableRow } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { VerificationsProps } from './VerificationsTable.types'
import { getFormattedDate } from '@percent/partner-dashboard/common/library/utility/date'
import { useTranslation } from 'react-i18next'
import {
  Table,
  ErrorView,
  TableCellStatusIcon,
  EmailTooltip as Tooltip
} from '@percent/partner-dashboard/common/components'
import { ReactComponent as RightArrowIcon } from '@percent/partner-dashboard/common/assets/images/right-arrow-icon.svg'
import styles from './VerificationsTable.module.scss'
import { useMemo } from 'react'
import { Chip, FilterList, FlagAvatar, SearchFilterList, SearchInput } from '@percent/lemonade'
import { useAgentEmailSearch, useProgramNameFilter, useStatusesFilter, usePartnerMessage } from './hooks'
import { useFeatureFlag } from '@percent/partner-dashboard/common/hooks'
import { useOrganisationNameFilter } from './hooks/useOrganisationNameFilter'
import { ValidationSubmission } from '@percent/partner-dashboard/services/applications/applicationsService.types'
import { Alpha3Code } from 'i18n-iso-countries'
import { useCountryFilter } from './hooks/useCountryFilter'
import { useCountries } from '@percent/partner-dashboard/common/hooks/useCountries/useCountries'

export function VerificationsTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage,
  queryParams,
  setQueryParams
}: Readonly<VerificationsProps>) {
  const { getCountryNameByCode } = useCountries()
  const { orgNameSearchFeatureFlag, countryCodeFilteringFeatureFlag } = useFeatureFlag()
  const { email, onEmailChange, handleClearEmailValue, handleKeyPress, validateEmail, emailError } =
    useAgentEmailSearch({
      queryParams,
      setQueryParams
    })
  const { statusesOptions, selectedStatuses, onStatusChange } = useStatusesFilter({ queryParams, setQueryParams })
  const { programNameOptions, isProgramNameFilterLoading, selectedProgramNames, onProgramNameChange } =
    useProgramNameFilter({
      queryParams,
      setQueryParams
    })
  const {
    organisationNameOptions,
    isOrganisationNameFilterLoading,
    selectedOrganisations,
    onOrganisationNameChange,
    searchValue,
    onSearchChange,
    onClearSearchValue,
    handleKeyPress: onOrganisationKeyPress,
    onOrganisationNameSearchButtonClick
  } = useOrganisationNameFilter({
    queryParams,
    setQueryParams
  })
  const partnerMessage = usePartnerMessage()
  const {
    countryOptions,
    isCountryFilterLoading,
    selectedCountries,
    onCountryChange,
    countrySearchValue,
    onCountrySearchChange,
    onClearCountrySearchValue,
    handleKeyPress: onCountryKeyPress,
    onCountrySearchButtonClick
  } = useCountryFilter({
    queryParams,
    setQueryParams
  })
  const columns = [
    { id: 'header.agentEmail', isSortable: false },
    { id: 'header.organisation', isSortable: false },
    ...(countryCodeFilteringFeatureFlag ? [{ id: 'header.country', isSortable: false }] : []),
    ...(programNameOptions.length > 1 ? [{ id: 'header.programName', isSortable: false }] : []),
    { id: 'header.overallResult', isSortable: false, props: { width: '110px' } },
    {
      id: 'header.applicationDate',
      isSortable: false,
      props: { width: '170px' }
    }
  ]

  const { t } = useTranslation()
  const { push } = useHistory()

  const rowVerificationClicked = (applicationId: string) => push(`/verifications/${applicationId}`)
  const agentEmail = (email: string | null) => email ?? ''

  const removeOrganisationNameOptionFilter = () => {
    setQueryParams({
      ...queryParams,
      organisationIds: [],
      validationRequestIds: []
    })
  }

  const removeCountryOptionFilter = () => {
    setQueryParams({
      ...queryParams,
      countryCodes: []
    })
  }
  const selectedOrganisationFilters = useMemo(
    () =>
      organisationNameOptions.filter(
        option =>
          queryParams?.organisationIds?.includes(option.value.organisationId) ||
          queryParams?.validationRequestIds?.includes(option.value.validationRequestId)
      ),
    [queryParams, organisationNameOptions]
  )

  const selectedCountryFilters = useMemo(
    () => countryOptions.filter(option => queryParams?.countryCodes?.includes(option.value)),
    [queryParams, countryOptions]
  )

  const filters = useMemo(
    () => (
      <div>
        <div className={styles.filtersContainer}>
          <SearchInput
            name="email"
            value={email}
            handleClearValue={handleClearEmailValue}
            onKeyDown={handleKeyPress}
            onChange={onEmailChange}
            onBlur={validateEmail}
            placeholder={t('table.verificationSearchPlaceholder')}
            status={emailError ? 'danger' : 'default'}
            statusMessage={emailError}
            width="480px"
          />

          <div className={styles.filtersWrapper}>
            {orgNameSearchFeatureFlag && (
              <SearchFilterList
                options={organisationNameOptions}
                isLoading={isOrganisationNameFilterLoading}
                selectedValues={selectedOrganisations}
                onChange={onOrganisationNameChange}
                searchValue={searchValue}
                onSearchChange={onSearchChange}
                handleClearValue={onClearSearchValue}
                onKeyDown={onOrganisationKeyPress}
                onButtonClick={onOrganisationNameSearchButtonClick}
                noResultsFoundText={
                  searchValue.length < 3
                    ? t('typography.organisationSearchNameFilter.noOptionsLessThan3Chars')
                    : undefined
                }
                keyName="id"
                testId="organisation-name-search-filter"
                buttonText={t('typography.organisationSearchNameFilter.button')}
                placeholder={t('typography.organisationSearchNameFilter.placeholder')}
                label={t('table.header.organisation')}
                placement="bottom"
              />
            )}
            {countryCodeFilteringFeatureFlag && (
              <SearchFilterList
                options={countryOptions}
                isLoading={isCountryFilterLoading}
                selectedValues={selectedCountries}
                onChange={onCountryChange}
                searchValue={countrySearchValue}
                onSearchChange={onCountrySearchChange}
                handleClearValue={onClearCountrySearchValue}
                onKeyDown={onCountryKeyPress}
                onButtonClick={onCountrySearchButtonClick}
                testId="country-search-filter"
                buttonText={t('typography.organisationSearchNameFilter.button')}
                placeholder={t('typography.countrySearchFilter.placeholder')}
                label={t('table.header.country')}
                placement="bottom"
              />
            )}
            {programNameOptions.length > 1 && (
              <FilterList
                label={t('table.header.programName')}
                options={programNameOptions}
                selectedValues={selectedProgramNames}
                onChange={onProgramNameChange}
                isLoading={isProgramNameFilterLoading}
                testId="program-filter"
              />
            )}
            <FilterList
              label={t('table.header.overallResult')}
              options={statusesOptions}
              selectedValues={selectedStatuses}
              onChange={onStatusChange}
              testId="overall-result-filter"
            />
          </div>
        </div>
        {!!selectedOrganisationFilters.length && (
          <div className={styles.filtersChipContainer}>
            <Chip
              label={t('table.header.organisation')}
              value={selectedOrganisationFilters.map(option => option.label).join(', ')}
              onClick={() => removeOrganisationNameOptionFilter()}
              testId="organisation-filter-chip"
            />
          </div>
        )}
        {!!selectedCountryFilters.length && (
          <div className={styles.filtersChipContainer}>
            <Chip
              label={t('table.header.country')}
              value={selectedCountryFilters.map(option => option.label).join(', ')}
              onClick={() => removeCountryOptionFilter()}
              testId="country-filter-chip"
            />
          </div>
        )}
      </div>
    ),
    [
      email,
      handleKeyPress,
      t,
      programNameOptions,
      selectedProgramNames,
      onProgramNameChange,
      isProgramNameFilterLoading,
      statusesOptions,
      selectedStatuses,
      onStatusChange
    ]
  )

  const getRowCountryCode = (row: ValidationSubmission): Alpha3Code | undefined =>
    row?.organisation?.countryCode || row?.validationRequest?.countryCode

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Table
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={columns}
      emptyTableText={t(email ? 'typography.noSearchResults' : 'typography.noRequestsTable')}
      emptyTableImageType={email ? 'query' : 'tick'}
      orderBy=""
      data-testid="validation-submissions-table"
      filtersContent={filters}
      alertMessage={partnerMessage}
    >
      {data?.map((row, i) => (
        <TableRow
          key={row?.id}
          className={styles.rowVerificationArrow}
          onClick={() => rowVerificationClicked(row.id)}
          data-testid={`table-row-${row.id}`}
        >
          <Tooltip title={agentEmail(row.agentVerificationEmail)}>
            <TableCell className={agentEmail(row.agentVerificationEmail) ? styles.tooltipCell : styles.naCell}>
              {agentEmail(row.agentVerificationEmail) || t('status.notAvailable')}
            </TableCell>
          </Tooltip>
          <TableCell>{row.organisationName ?? row.validationRequestName}</TableCell>
          {countryCodeFilteringFeatureFlag && getRowCountryCode(row) && (
            <TableCell>
              <div className={styles.countryDetailsWrapper}>
                <FlagAvatar code3={getRowCountryCode(row)!} />
                <span>{getCountryNameByCode(getRowCountryCode(row)!)}</span>
              </div>
            </TableCell>
          )}
          {programNameOptions.length > 1 && (
            <TableCell>{row.configuration?.name || t('typography.unnamedProgram')}</TableCell>
          )}
          <TableCell>
            <TableCellStatusIcon statusType={row?.status} />
          </TableCell>
          <TableCell>{getFormattedDate(row?.createdAt)}</TableCell>
          <TableCell align="right" className={styles.arrow}>
            <RightArrowIcon />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
