import React from 'react'

import styles from './PercentLogo.module.scss'

export function PercentLogo({ className, styleList }: { className: string; styleList?: object }) {
  return (
    <div className={styles[className]} style={styleList}>
      <svg width="140.2px" height="39px" viewBox="0 0 267 65" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="percent-logo-coral-red-RGB-(1)" fillRule="nonzero" fill="#FF4D5A">
            <path
              d="M139.208301,41.8661789 C133.834293,41.8661789 129.478358,37.5102439 129.478358,32.1362358 C129.478358,26.7622276 133.835016,22.4062927 139.208301,22.4062927 C141.249512,22.4062927 143.143114,23.0358049 144.707488,24.1095935 L150.34922,16.319561 C143.327626,11.2342602 134.287976,11.2480081 127.281577,16.3550163 C118.524122,22.7384146 116.598683,35.0124553 122.982081,43.7699106 C129.36548,52.5273659 141.63952,54.4520813 150.396976,48.0694065 L144.659008,40.1968862 C143.104041,41.2504146 141.227805,41.8661789 139.208301,41.8661789 Z"
              id="Shape"
            />
            <path
              d="M20.1147236,12.586626 C9.31678049,12.5656423 0.545577236,21.3028374 0.525317073,32.1007805 C0.524593496,32.344626 0.455130081,64.0763821 0.455130081,64.0763821 L11.6611707,64.0980894 L11.6886667,49.8016504 C14.2197398,51.0056829 17.0496504,51.6843984 20.038748,51.690187 C30.8366911,51.7111707 39.6078943,42.9739756 39.6281545,32.1760325 C39.6498618,21.3780894 30.9126667,12.6076098 20.1147236,12.586626 Z M20.0546667,43.8234553 C13.6018049,43.8111545 8.38047154,38.569561 8.39277236,32.1166992 C13.2769187,32.1166992 27.1515122,32.161561 31.7621463,32.161561 C31.749122,38.6144228 26.5075285,43.8357561 20.0546667,43.8234553 Z"
              id="Shape"
            />
            <polygon
              id="Shape"
              points="257.364122 12.6213577 257.364122 0.277130081 246.20439 0.277130081 246.20439 12.6213577 237.863715 12.6213577 237.863715 22.4475366 246.20439 22.4475366 246.20439 51.7393902 257.364122 51.7393902 257.364122 22.4475366 266.54487 22.4475366 266.54487 12.6213577"
            />
            <path
              d="M218.963878,12.942626 C213.84674,11.9766504 208.357683,13.0583984 203.839667,16.3984309 C198.717463,20.1849106 195.999707,26.0154959 195.978,31.9307398 L195.975829,31.9343577 L195.952675,51.7053821 L207.178976,51.7053821 L207.178976,31.4502846 C207.534976,26.9011545 210.714374,23.2543252 214.722992,22.7666341 C214.938618,22.7507154 215.155691,22.7391382 215.375659,22.7376911 C216.255528,22.7319024 217.107902,22.8469512 217.919756,23.0625772 C221.501463,24.1899106 224.137455,27.9025854 224.137455,32.2990407 L224.137455,51.7473496 L234.876789,51.7473496 L234.876789,32.2990407 C234.876065,22.3100569 227.907293,14.056935 218.963878,12.942626 Z"
              id="Shape"
            />
            <path
              d="M72.923561,15.0699431 C63.5062033,9.85946341 51.6286829,13.2356748 46.3950488,22.6096179 C44.4290894,26.1312683 43.680187,29.998065 44.0101382,33.7302764 C44.3060813,37.8098049 45.8936098,41.8292764 48.8154146,45.1374715 C55.929626,53.1916098 68.2557642,53.9810325 76.3475285,46.8993821 L69.9525528,39.6592683 C68.5082927,40.8379756 66.7065854,41.614374 64.7066179,41.7967154 C60.3354878,42.1954065 56.3782439,39.6339431 54.8348537,35.7700407 L82.592,35.7700407 C84.2460976,27.3504959 80.4574472,19.2384715 72.923561,15.0699431 Z M54.6944797,28.7773902 C55.2509106,27.2007154 56.2110976,25.8013171 57.461439,24.7123333 C58.098187,24.1826748 58.8145285,23.7260976 59.6039512,23.3621382 C64.4519187,21.1270081 70.202187,23.2282764 72.4467236,28.053813 C72.5581545,28.2925935 72.6580081,28.5342683 72.7477317,28.7766667 L54.6944797,28.7766667 L54.6944797,28.7773902 Z"
              id="Shape"
            />
            <path
              d="M181.595455,15.0699431 C172.178098,9.85946341 160.300577,13.2356748 155.066943,22.6096179 C153.100984,26.1312683 152.352081,29.998065 152.682033,33.7302764 C152.977976,37.8098049 154.565504,41.8292764 157.487309,45.1374715 C164.60152,53.1916098 176.927659,53.9810325 185.019423,46.8993821 L178.624447,39.6592683 C177.180187,40.8379756 175.37848,41.614374 173.378512,41.7967154 C169.007382,42.1954065 165.050138,39.6339431 163.506748,35.7700407 L191.263894,35.7700407 C192.917992,27.3504959 189.129341,19.2384715 181.595455,15.0699431 Z M163.366374,28.7773902 C163.922805,27.2007154 164.882992,25.8013171 166.133333,24.7123333 C166.770081,24.1826748 167.486423,23.7260976 168.275846,23.3621382 C173.123813,21.1270081 178.874081,23.2282764 181.118618,28.053813 C181.230049,28.2925935 181.329902,28.5342683 181.419626,28.7766667 L163.366374,28.7766667 L163.366374,28.7773902 Z"
              id="Shape"
            />
            <polygon id="Shape" points="118.459 16.2898943 118.452488 16.2855528 118.459 16.2906179" />
            <path
              d="M118.452488,16.2855528 C117.369293,15.4961301 116.236894,14.8282683 115.071211,14.2819675 C113.569065,13.6090407 111.965618,13.1510163 110.292707,12.942626 C105.842707,12.1025528 101.112683,12.8131057 96.9832276,15.2088699 C96.9333008,15.2385366 96.8826504,15.2667561 96.8327236,15.2971463 C96.7314228,15.3572033 96.6322927,15.4223252 96.5317154,15.4845528 C96.1496667,15.7247805 95.7712358,15.9758618 95.3993171,16.2450325 C91.5788293,19.0090976 89.0499268,22.8947073 87.9341707,27.1240163 C87.9247642,27.1594715 87.9146341,27.1949268 87.9059512,27.2311057 C87.8524065,27.4402195 87.805374,27.6507805 87.7583415,27.8613415 C87.7149268,28.0603252 87.6722358,28.2600325 87.6353333,28.4604634 C87.6194146,28.545122 87.6034959,28.6297805 87.5883008,28.714439 C87.4066829,29.7759268 87.3104472,30.8526098 87.3068293,31.932187 L87.3046585,31.9358049 L87.3032114,33.000187 L87.2807805,51.7061057 L98.5070813,51.7061057 L98.5070813,49.8696667 L98.5070813,31.4510081 C98.8630813,26.901878 102.04248,23.2550488 106.051098,22.7673577 C106.266724,22.751439 106.483797,22.7398618 106.703764,22.7384146 C107.583634,22.732626 108.436008,22.8476748 109.247862,23.0633008 C110.357106,23.4127886 111.374455,24.0104634 112.252154,24.7991626 L118.459,16.2913415 C118.456829,16.2884472 118.454659,16.287 118.452488,16.2855528 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
