import React from 'react'
import styled from 'styled-components'
import { ModalContentProps } from './modal-content.types'
import { Text } from '../text'

export const StyledModalFooter = styled.div`
  ${({ theme }) =>
    `
    max-height: 28rem;

    padding: ${theme.sizes[4]} ${theme.sizes[6]};
    `}
`

export const ModalContent = ({ children }: ModalContentProps) => {
  return (
    <StyledModalFooter>
      <Text>{children}</Text>
    </StyledModalFooter>
  )
}
