import React from 'react'

import { TableCell } from '@material-ui/core'
import { inMiddleEllipsis } from '../../library/utility/utility'
import styles from './TableCellEllipsisValue.module.scss'
import { TableCellEllipsisProps } from './TableCellEllipsis.types'

export function TableCellEllipsisValue({ id, ellipsis = true }: TableCellEllipsisProps) {
  return (
    <TableCell className={styles.tableCellId}>
      <span className={styles.spanId}>{(ellipsis && inMiddleEllipsis(id)) || id}</span>
    </TableCell>
  )
}
