import { AxiosInstance } from 'axios'
import { OrganisationExtended } from '../nonprofits/nonprofitsService.types'

export type DonationServiceCapabilities = {
  percentClient: AxiosInstance
}

export enum DonationListStatusFilterEnum {
  Active = 'ACTIVE',
  AwaitingPayment = 'AWAITING_PAYMENT',
  ReceivedPayment = 'RECEIVED_PAYMENT',
  Settled = 'SETTLED',
  Disbursed = 'DISBURSED',
  Cancelled = 'CANCELLED',
  RequestedPayment = 'REQUESTED_PAYMENT'
}

export type Donation = {
  amount: number
  anonymous: boolean | null
  cancelledAt: string | null
  consentedToBeContactedByOrg: boolean | null
  createdAt: string
  currencyCode: string
  email?: string | null
  firstName?: string | null
  giftAidId: string | null
  id: string
  lastName?: string | null
  metadata: string | null
  organisationId: string | null
  status: DonationListStatusFilterEnum
  type: 'direct' | 'hosted' | 'matched' | 'external'
  userId: string
  organisation: OrganisationExtended
  accountId?: string
}

export type GetDonationsListProps = {
  pageSize?: number | null
  startDate?: string | null
  endDate?: string | null
  status?: string[] | null
  'expand[]'?: string
}
