import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { object, string } from 'yup'

import { AuthForm } from '../AuthForm'
import { AuthSuccessText } from '../AuthSuccessText'

import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { passwordRequirementRegExp } from '@percent/partner-dashboard/common/library/utility/validation'
import { FieldError } from '@percent/partner-dashboard/common/components'
import { useTranslation } from 'react-i18next'

export function SetPassword() {
  const { authService } = useServices()
  const { search } = useLocation()
  const hash = new URLSearchParams(search).get('userHash')
  const [{ success, errorMessage, isLoading }, { apiFunc: setPassword }] = useMutation(authService.setPassword)
  const { push } = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (!hash) {
      push('/signin')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push])

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: () =>
      object().shape({
        password: string()
          .min(8, t('form.minimum8Characters'))
          .max(64)
          .matches(passwordRequirementRegExp, t('errorMessage.passwordRequirement'))
          .required('Required')
      }),
    onSubmit: ({ password }: { password: string }) => {
      setPassword({ password, hash: hash as string })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <AuthForm heading={t('typography.setPassword')} handleSubmit={handleSubmit}>
      {success ? (
        <AuthSuccessText text={t('typography.passwordSetSuccessfully')} showSignInLink={false} showSignInButton />
      ) : (
        <>
          <FormikProvider value={formik}>
            <FormField
              showStrenghtMeter
              label="Password"
              status={touched.password && errors.password ? 'danger' : 'default'}
              statusMessage={errors.password}
              data-testid="password"
            >
              <TextInput
                type="password"
                name="password"
                placeholder="Enter your password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </FormField>
          </FormikProvider>
          {errorMessage && <FieldError error={errorMessage} />}
          <Spacer size={5} axis="vertical" />
          <Button stretch size="large" data-testid="auth-active-button" loading={isLoading} type="submit">
            {t('button.setPassword')}
          </Button>
        </>
      )}
    </AuthForm>
  )
}
