import { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'

import { PaginationProps } from './useQueryList.types'
import { getQueryParamsFromUrl } from '@percent/partner-dashboard/common/library/utility/utility'
import { PBPLinks, PBPListResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'
import { APIErrorHandler } from '@percent/partner-dashboard/common/library/APIErrorHandler'
import { useTranslation } from 'react-i18next'

const defaultPageData = { pageSize: 10 }

export const useQueryList = <A extends PaginationProps, B>(
  api: (_: A) => Promise<AxiosResponse<PBPListResponse<B>>>,
  initialQuery?: A,
  mount = true
) => {
  const { t } = useTranslation()
  const [body, setBody] = useState<PBPListResponse<B> | null>(null)
  const data = body?.data || null
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [status, setStatus] = useState<null | number>(null)
  const [paginationLinks, setPaginationLinks] = useState<PBPLinks | null>(null)
  const [totalPages, setTotalPages] = useState(0)
  const [totalResults, setTotalResultsNumber] = useState<null | number>(null)
  const [onMount, setOnMount] = useState(mount)
  const [queryParams, setQueryParams] = useState<A>({ ...defaultPageData, ...initialQuery } as A)
  const query = useCallback((newQueryParams: A) => {
    setOnMount(true)
    setQueryParams({ ...defaultPageData, ...newQueryParams })
  }, [])
  const refresh = useCallback(() => {
    setQueryParams({ ...queryParams })
  }, [queryParams])
  const nextPage = useCallback(() => {
    if (paginationLinks?.next) {
      setQueryParams({ ...queryParams, ...getQueryParamsFromUrl(paginationLinks.next) })
    }
  }, [queryParams, paginationLinks?.next])
  const previousPage = useCallback(() => {
    if (paginationLinks?.prev) {
      setQueryParams({ ...queryParams, ...getQueryParamsFromUrl(paginationLinks.prev) })
    }
  }, [queryParams, paginationLinks?.prev])

  const fetchData = async () => {
    let didCancel = false
    setIsLoading(true)
    try {
      const { data: responseBody, status: apiStatus } = await api(queryParams)

      if (!didCancel) {
        setTotalPages(responseBody.totalResults ? Math.ceil(responseBody.totalResults / responseBody.pageSize) - 1 : 0)
        setTotalResultsNumber(responseBody?.totalResults)

        if (apiStatus === 204 || responseBody.data.length === 0) {
          setErrorMessage(t('errorMessage.noResult'))
        } else {
          // eslint-disable-next-line no-underscore-dangle
          setPaginationLinks(responseBody._links)
        }
        setBody(responseBody)
        setStatus(apiStatus)
        setIsLoading(false)
        setErrorMessage('')
      }
    } catch (err) {
      if (!didCancel) {
        setErrorMessage(APIErrorHandler(err?.response?.data?.error))
        setError(err?.response?.data?.error)
        setIsLoading(false)
      }
    }

    return () => {
      didCancel = true
    }
  }

  useEffect(() => {
    if (onMount) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  return [
    {
      data,
      isLoading,
      errorMessage,
      error,
      queryParams,
      totalPages,
      status,
      totalResults
    },
    {
      query,
      nextPage: paginationLinks?.next ? nextPage : null,
      previousPage: paginationLinks?.prev ? previousPage : null,
      refresh
    }
  ] as const
}
