import React from 'react'
import { Typography } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import { DisableTwoFactorDeviceProps } from '../ChangeTwoFactorDevice/ChangeTwoFactorDevice.types'

import { DialogContainer } from '@percent/partner-dashboard/common/components'
import styles from './DisableTwoFactorDevice.module.scss'
import { ReactComponent as Close } from '@percent/partner-dashboard/common/assets/images/close.svg'
import { useTranslation } from 'react-i18next'
import { Button, Feedback, FormField, Spacer, TextInput } from '@percent/lemonade'
import { CentralCardLayout } from '@percent/domain/iam'

export function DisableTwoFactorDevice({
  isOpened,
  onClose,
  errorMessage,
  isLoading,
  disableFunc
}: DisableTwoFactorDeviceProps) {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: () =>
      yup.object().shape({
        password: yup.string().min(8).max(64).required('Required')
      }),
    onSubmit: ({ password }: { password: string }) => {
      disableFunc({ password })
    }
  })

  const { errors, values, touched, dirty, isValid, handleChange, handleBlur, handleSubmit } = formik

  return (
    <DialogContainer
      openModal={isOpened}
      onClose={onClose}
      headerTitle="Change Two-Factor Device"
      fullscreen
      withoutHeader
    >
      <button className={styles.close} type="button" data-testid="close-button" onClick={onClose}>
        <Close />
      </button>
      <div className={styles.layoutContainer}>
        <CentralCardLayout heading={t('2fa.disable2fa')} handleSubmit={handleSubmit}>
          <Typography variant="body1" className={styles.headerTwo}>
            {t('2fa.disable2faTitle')}
          </Typography>
          <Spacer size={8} axis="vertical" />
          <FormikProvider value={formik}>
            <FormField
              label={t('2fa.passwordLabelDisable')}
              status={touched.password && errors.password ? 'danger' : 'default'}
              statusMessage={errors.password}
            >
              <TextInput
                type="password"
                name="password"
                placeholder={t('2fa.passwordPlaceholder')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </FormField>
          </FormikProvider>
          {errorMessage && (
            <>
              <Spacer size={4} axis="vertical" />
              <Feedback variant="critical">{errorMessage}</Feedback>
            </>
          )}
          <Spacer size={6} axis="vertical" />
          <Button type="submit" size="large" stretch disabled={!(isValid && dirty)} loading={isLoading}>
            {t('button.disable2fa')}
          </Button>
        </CentralCardLayout>
      </div>
    </DialogContainer>
  )
}
