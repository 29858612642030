import * as Sentry from '@sentry/react'

import { environment } from '@percent/partner-dashboard/config'
import { environments } from '@percent/partner-dashboard/constants/environments'

const logToSentry = (error: Error) => {
  Sentry.captureException(error)
}

// eslint-disable-next-line no-console
const consoleErr = (error: Error) => console.log(error)

export const logger = (error: Error) => {
  switch (environment) {
    case environments.STAGING:
    case environments.PREPROD:
    case environments.PRODUCTION:
      return logToSentry(error)
    case environments.LOCAL:
    case environments.LOCAL_SERVER:
    default:
      return consoleErr(error)
  }
}
