import { Badge, Spacer } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { EligilityTagsBadgesProps } from './EligibilityTagsBadges.types'
import { Fragment } from 'react'

export const EligibilityTagsBadges = ({ eligibility, eligibilityNotAvailable }: EligilityTagsBadgesProps) => {
  const { t } = useTranslation()

  if (eligibility?.status === 'pending' || eligibility?.status === 'pending_validation_request') {
    return (
      <Badge icon="clock" variant="default">
        {t('status.pending')}
      </Badge>
    )
  }

  if (!eligibility?.results?.confirmedActivitySubTags?.length && eligibilityNotAvailable) {
    return <Badge variant="default">{t('status.notAvailable')}</Badge>
  }

  if (!eligibility?.results?.confirmedActivitySubTags?.length) {
    return (
      <Badge variant="default">
        {t(
          eligibility?.results?.confirmedActivitySubTags && eligibility?.results?.confirmedActivitySubTags.length === 0
            ? 'status.notAvailable'
            : 'status.cantDefine'
        )}
      </Badge>
    )
  }

  return (
    <div>
      {eligibility?.results?.confirmedActivitySubTags?.map(({ id, name }) => (
        <Fragment key={id}>
          <Badge key={id} variant="default">
            {name}
          </Badge>
          <Spacer size={2} />
        </Fragment>
      ))}
    </div>
  )
}
