import { OrganisationTypes } from '@percent/partner-dashboard/services/applications/applicationsService.types'
import { Organisation } from '@percent/partner-dashboard/services/nonprofits/nonprofitsService.types'

export const organisation: Organisation = {
  id: 'staging_organisation_000000',
  name: 'Test Organisation Name',
  displayName: null,
  description: '',
  countryCode: 'USA',
  type: '',
  logo: null,
  images: [''],
  registry: '',
  registryId: '',
  website: 'https://test.example',
  address: 'Mokotowska 4/2, Test, Warsaw, 02-786',
  postcode: '55555',
  registrationDate: null,
  registrationActive: true,
  currencyCode: 'USD',
  trustees: null,
  claimedAt: null,
  types: [OrganisationTypes.Nonprofit],
  createdAt: '2022-05-05T18:50:52.788Z'
}
