import React from 'react'
import { Grid } from '@material-ui/core'
import { Button, Spacer, Text } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { Modal } from '@percent/partner-dashboard/common/components/modal/Modal'
import { RemoveUserModalProps } from './RemoveUserModal.types'
import { FormikProvider, useFormik } from 'formik'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { object, string } from 'yup'

export const RemoveUserModal = ({ openModalData, onClose, refresh }: RemoveUserModalProps) => {
  const { t } = useTranslation()
  const { iamService } = useServices()

  const [{ isLoading: isSaving }, { apiFunc: deleteAccount }] = useMutation(iamService.deleteAccount, () => refresh())

  const formik = useFormik({
    initialValues: { userId: openModalData?.userId },
    validationSchema: () =>
      object().shape({
        userId: string()
      }),
    onSubmit: () => {
      if (openModalData) {
        deleteAccount({
          userId: openModalData.userId
        })
        onClose()
      }
    }
  })

  const { errors, values, handleSubmit } = formik

  return (
    <Modal title={t('dialog.removeUser.title')} openModal={Boolean(openModalData)} onClose={onClose}>
      {values.userId}
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Spacer size={5} axis="vertical" />
          <span style={{ fontWeight: 500 }}>{openModalData && openModalData.email}</span>{' '}
          <Text> {t('dialog.removeUser.willBeRemovedInfo')}</Text>
          <Spacer size={4} axis="vertical" />
          <Text>{t('dialog.removeUser.areYouSureInfo')}</Text>
          <Spacer size={10} axis="vertical" />
          <Grid container justifyContent="flex-end">
            <Button type="submit" size="large" loading={isSaving}>
              {t('button.removeMember')}
            </Button>
            <Spacer size={4} axis="horizontal" />
            <Button variant="secondary" size="large">
              {t('button.cancel')}
            </Button>
          </Grid>
        </FormikProvider>
      </form>
    </Modal>
  )
}
