import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { Button, Copy } from '@percent/lemonade'
import styles from './KeyListTable.module.scss'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'

export function KeyList() {
  const [revealSecret, setRevealSecret] = useState(false)
  const { partner } = useAuthState()
  const { t } = useTranslation()

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('typography.name')}</TableCell>
          <TableCell>{t('typography.token')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('typography.publicKey')}</TableCell>
          <TableCell>
            <Copy message={t('typography.copyPublicKey')} text={partner?.publicKey || ''}>
              {partner?.publicKey}
            </Copy>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('typography.secretKey')}</TableCell>
          {revealSecret ? (
            <TableCell>
              <Copy message={t('typography.copySecretKey')} text={partner?.secretKey || ''}>
                {partner?.secretKey}
              </Copy>
            </TableCell>
          ) : (
            <TableCell className={styles.relativeCellStyle}>
              <div className={styles.blurCellStyle}>{partner?.secretKey}</div>
              <div className={styles.buttonStyle}>
                <Button
                  variant="primary"
                  data-testid="reveal-btn"
                  onPress={() => {
                    setRevealSecret(true)
                  }}
                >
                  {t('button.reveal')}
                </Button>
              </div>
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  )
}
