import { createContext } from 'react'
import { Illustration } from '../illustrated-message/illustrated-message.types'

export type ColumnProps = {
  width: string
}

type Column = {
  id: string
  content?: React.ReactNode
  props?: ColumnProps
}

export type TableContextType = {
  columns: Column[]
  totalResults: number | null
  isLoading: boolean
  illustration: Illustration
  emptyTableTitle: string
  emptyTableDescription: string
}

export const TableContext = createContext<TableContextType | undefined>(
  undefined
)

export type PaginationActionsProps = {
  totalResultsText?: string
  previousPage: (() => void) | null
  nextPage: (() => void) | null
}
