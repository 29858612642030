import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { VerificationsProps } from '../VerificationsTable.types'
import { ChangeEvent, EventHandler, useCallback, useEffect, useMemo, useState, KeyboardEvent } from 'react'
import { ValidationSubmissionOrganisationNameSearchResult } from '@percent/partner-dashboard/services/applications/applicationsService.types'

export const useOrganisationNameFilter = ({
  queryParams,
  setQueryParams
}: Pick<VerificationsProps, 'queryParams' | 'setQueryParams'>) => {
  const { applicationsService } = useServices()

  const [{ data, isLoading }, { query }] = useQuery(
    applicationsService.getValidationSubmissionIdsByOrganisationName,
    {
      query: '',
      validationRequestIds: queryParams.validationRequestIds,
      organisationIds: queryParams.organisationIds
    },
    !!queryParams.validationRequestIds || !!queryParams.organisationIds,
    !!queryParams.validationRequestIds || !!queryParams.organisationIds
  )

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedOrganisations, setSelectedOrganisations] = useState<
    ValidationSubmissionOrganisationNameSearchResult[]
  >([])

  const stringifiedOptionsSet: Set<string> = new Set(
    data?.results.map(result =>
      JSON.stringify({
        name: result.name,
        organisationId: result.organisationId,
        validationRequestId: !result.organisationId ? result.validationRequestId : null,
        id: result.organisationId ?? result.validationRequestId
      })
    )
  )

  const unqiueOptions = Array.from(stringifiedOptionsSet).map(result => JSON.parse(result))

  const organisationNameOptions: { label: string; value: ValidationSubmissionOrganisationNameSearchResult }[] = useMemo(
    () => [
      ...selectedOrganisations.map(option => ({
        label: option.name,
        value: option
      })),
      ...(unqiueOptions
        ? unqiueOptions
            .filter(
              result =>
                !selectedOrganisations.find(value => {
                  if (result.organisationId) return value.organisationId === result.organisationId
                  if (!result.organisationId && result.validationRequestId)
                    return value.validationRequestId === result.validationRequestId
                  return false
                })
            )
            .map(option => ({
              label: option.name,
              value: option
            }))
        : [])
    ],
    [data, queryParams.organisationIds, queryParams.validationRequestIds]
  )

  const onOrganisationNameChange = useCallback(
    (values: ValidationSubmissionOrganisationNameSearchResult[]) => {
      setSelectedOrganisations(values)
    },
    [setSelectedOrganisations]
  )

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleclearSearchValue = () => {
    setSearchValue('')
  }

  useEffect(() => {
    if (searchValue?.length > 2) {
      query({
        query: searchValue,
        organisationIds: queryParams.organisationIds,
        validationRequestIds: queryParams.validationRequestIds
      })
    }
  }, [searchValue])

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (event.key === 'Enter') {
      return setSearchValue((event.target as HTMLInputElement).value)
    }

    if (event.key === 'Escape') {
      return setSearchValue('')
    }
  }

  const handleOrganisationNameSearchButtonClick = useCallback(() => {
    const organisationIds: string[] = []
    const validationRequestIds: string[] = []

    for (const organisation of selectedOrganisations) {
      if (organisation.organisationId) {
        organisationIds.push(organisation.organisationId)
      } else if (!organisation.organisationId && organisation.validationRequestId) {
        validationRequestIds.push(organisation.validationRequestId)
      }
    }

    setQueryParams({
      ...queryParams,
      ...(organisationIds.length ? { organisationIds } : { organisationIds: [] }),
      ...(validationRequestIds.length ? { validationRequestIds } : { validationRequestIds: [] })
    })
  }, [queryParams, selectedOrganisations, setQueryParams])

  useEffect(() => {
    if (organisationNameOptions.length && !selectedOrganisations.length) {
      setSelectedOrganisations(
        organisationNameOptions
          .filter(option => {
            if (option.value.organisationId) return queryParams?.organisationIds?.includes(option.value.organisationId)
            if (!option.value.organisationId && option.value.validationRequestId)
              return queryParams?.validationRequestIds?.includes(option.value.validationRequestId)
            return false
          })
          .map(({ value }) => value)
      )
    }
  }, [data])

  useEffect(() => {
    setSelectedOrganisations(
      organisationNameOptions
        .filter(option => {
          if (option.value.organisationId) return queryParams?.organisationIds?.includes(option.value.organisationId)
          if (!option.value.organisationId && option.value.validationRequestId)
            return queryParams?.validationRequestIds?.includes(option.value.validationRequestId)
          return false
        })
        .map(({ value }) => value)
    )
  }, [queryParams])

  return {
    organisationNameOptions,
    isOrganisationNameFilterLoading: isLoading,
    selectedOrganisations,
    onOrganisationNameChange,
    onSearchChange: handleSearchChange,
    onClearSearchValue: handleclearSearchValue,
    searchValue,
    onOrganisationNameSearchButtonClick: handleOrganisationNameSearchButtonClick,
    handleKeyPress
  }
}
