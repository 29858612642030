import { useWizard } from '../Wizard/useWizard/useWizard'

import { useDownloadStringFile } from '@percent/partner-dashboard/common/hooks/useDownloadStringFile/useDownloadStringFile'
import { ReactComponent as Download } from '@percent/partner-dashboard/common/assets/images/download.svg'
import styles from './SaveBackupCodeStep.module.scss'
import { useTranslation } from 'react-i18next'
import { SaveBackupCodeStepProps } from './SaveBackupCodeStep.types'
import { Button, Copy, Spacer } from '@percent/lemonade'
import { AuthForm } from '../../../AuthForm'

export function SaveBackupCodeStep({ otpCode = '' }: SaveBackupCodeStepProps) {
  const { t } = useTranslation()
  const { setIsAuthenticationComplete } = useWizard()
  const downloadCode = useDownloadStringFile(otpCode, 'otp-backup.txt')

  const handleClick = () => setIsAuthenticationComplete(true)

  return (
    <AuthForm heading={t('2fa.saveBackupCode')}>
      <p className={styles.subtext}>{t('2fa.saveBackupCodeSubtext')}</p>
      <Spacer axis="vertical" size={8} />
      <Button variant="secondary" disabled stretch>
        {otpCode}
      </Button>
      <div className={styles.actionWrapper}>
        <div className={styles.copyWrapper}>
          <Copy text={otpCode} />
          <p className={styles.actionText}>{t('typography.copy')}</p>
        </div>
        <div className={styles.downloadWrapper}>
          <Download className={styles.download} onClick={downloadCode} />
          <p className={styles.actionText}>{t('typography.download')}</p>
        </div>
      </div>
      <Button type="button" size="large" onPress={handleClick} stretch>
        {t('button.done')}
      </Button>
    </AuthForm>
  )
}
