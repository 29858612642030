import React, { useMemo } from 'react'

import { BreadcrumbsContext } from '../BreadcrumbsContext'

import { BreadcrumbsContextControllerProps } from './BreadcrumbsContextController.types'

export function BreadcrumbsContextController({ children, path, setPath }: BreadcrumbsContextControllerProps) {
  const breadcrumbsContextValue = useMemo(
    () => ({
      path,
      setPath
    }),
    [path, setPath]
  )

  return <BreadcrumbsContext.Provider value={breadcrumbsContextValue}>{children}</BreadcrumbsContext.Provider>
}
