import { CardDashboard, CardContent, Spacer } from '@percent/lemonade'
import { VerificationPartnerFieldsCardProps } from './VerificationPartnerFieldsCard.types'

import { useTranslation } from 'react-i18next'

export const VerificationPartnerFieldsCard = ({ partnerFields }: VerificationPartnerFieldsCardProps) => {
  const { t } = useTranslation()

  return (
    <CardDashboard title={t('typography.partnerFields')}>
      {Object.entries(partnerFields).map(([title, answer], index) => {
        return (
          <CardContent key={title} title={title} testId={`verification-partner-fields-${index}`}>
            {Array.isArray(answer) ? (
              answer.map((option: string | null, index) => {
                const delimiter = index < answer.length - 1 ? ',' : ''

                return (
                  <>
                    <span key={option}>{option ? `${option}${delimiter}` : '-'}</span>
                    <Spacer size={1} axis="horizontal" />
                  </>
                )
              })
            ) : (
              <span>{answer || '-'}</span>
            )}
          </CardContent>
        )
      })}
    </CardDashboard>
  )
}
