import { useContext } from 'react'

import { CountriesContext } from '@percent/partner-dashboard/context/countries/CountriesContext'

export const useCountries = () => {
  const context = useContext(CountriesContext)

  if (context === undefined) {
    throw new Error('useCountries must be used within an CountriesContextController')
  }

  return context
}
