import { Country, countryServiceCapabilities } from './countryService.types'
import { PBPListResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'

export const countryService = ({ percentClient }: countryServiceCapabilities) => ({
  getCountries: (params: { query?: string }) =>
    percentClient.get<PBPListResponse<Country>>('v1/countries', {
      params: {
        hasRegistry: true,
        ...params
      },
      withCredentials: true
    })
})
