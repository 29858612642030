import { createContext, useContext } from 'react'

import { Services } from '@percent/partner-dashboard/services'

export const ServiceContext = createContext<Services | undefined>(undefined)

export const useServices = () => {
  const ctx = useContext(ServiceContext)

  if (!ctx) {
    throw new Error(`${ServiceContext.displayName} context not found`)
  }

  return ctx
}
