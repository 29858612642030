import { StatusType } from '@percent/partner-dashboard/constants/status/statusType'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { VerificationsProps } from '../VerificationsTable.types'

export const useStatusesFilter = ({
  queryParams,
  setQueryParams
}: Pick<VerificationsProps, 'queryParams' | 'setQueryParams'>) => {
  const { t } = useTranslation()

  const statusesOptions = [
    { label: t('table.pending'), value: StatusType.Pending },
    { label: t('table.approved'), value: StatusType.Succeeded },
    { label: t('table.rejected'), value: StatusType.Failed }
  ]

  const onStatusChange = useCallback(
    (statuses: string[]) => {
      let updatedStatuses: string[]

      if (statuses.includes(StatusType.Pending) && !statuses.includes(StatusType.Processed)) {
        updatedStatuses = [...statuses, StatusType.Processed]
      } else if (!statuses.includes(StatusType.Pending) && statuses.includes(StatusType.Processed)) {
        updatedStatuses = statuses.filter(s => s !== StatusType.Processed)
      } else {
        updatedStatuses = statuses
      }

      setQueryParams({
        statuses: updatedStatuses
      })
    },
    [setQueryParams]
  )

  return {
    statusesOptions,
    onStatusChange,
    selectedStatuses: queryParams?.statuses?.filter(s => s !== StatusType.Processed) || []
  }
}
