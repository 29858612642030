import React from 'react'

import { useWizard } from '../Wizard/useWizard/useWizard'

import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { ConfirmationCodeStep } from './ConfirmationCodeStep'
import { ConfirmationCodeContainerProps } from './ConfirmationCodeStep.types'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'

export function ConfirmationCodeStepContainer({ setUserData }: ConfirmationCodeContainerProps) {
  const { setNextStep } = useWizard()
  const { authService } = useServices()
  const [{ errorMessage, isLoading }, { apiFunc: verifyFunc }] = useMutation(
    authService.confirmOtpKey,
    ({ data: { data } }) => {
      setUserData(data)
      setNextStep()
    }
  )

  return <ConfirmationCodeStep errorMessage={errorMessage} isLoading={isLoading} verifyFunc={verifyFunc} />
}
