import en from '@percent/partner-dashboard/i18n/data/en/en.json'

export const apiErrorMessage = {
  GENERIC_ERROR: en['errorMessage.genericError'],
  NO_RESULT: en['errorMessage.noResult'],
  FORBIDDEN_PASSWORD: en['errorMessage.forbiddenPassword'],
  TOO_MANY_CHANGE_PASSWORD_REQUESTS: en['errorMessage.tooManyChangePasswordRequests'],
  TWO_FA_INVALID_TOKEN: en['errorMessage.twoFaInvalidToken'],
  TWO_FA_INVALID_TOKEN_OTP_CODE: en['errorMessage.twoFaInvalidTokenOtpCode']
}
