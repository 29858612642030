import React from 'react'
import { SplitFactory } from '@splitsoftware/splitio-react'

import { EnvironmentOverlay } from './dashboard/layout/environmentOverlay/EnvironmentOverlay'
import { ToastContainer } from '@percent/partner-dashboard/containers/toast/ToastContainer'
import './app.scss'
import { AuthRoutes } from './auth/routes/Routes'
import { AppProviders } from './providers/AppProviders/AppProviders'
import { AppRoutes } from './routes/Routes'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'
import { splitSdkConfig } from '@percent/partner-dashboard/app/providers/SplitSdkProvider/SplitSdkProvider'

export function App() {
  const { isAuthorised } = useAuthState()

  return (
    <SplitFactory config={splitSdkConfig} updateOnSdkTimedout>
      <AppProviders>
        <EnvironmentOverlay />
        <ToastContainer>{isAuthorised ? <AppRoutes /> : <AuthRoutes />}</ToastContainer>
      </AppProviders>
    </SplitFactory>
  )
}
