/* eslint-disable */
import React from 'react'
import { Menu, MenuItem, TableCell, Typography } from '@material-ui/core'

import styles from './EllipsisTableCell.module.scss'
import { ReactComponent as EllipsisIcon } from '@percent/partner-dashboard/common/assets/images/ellipsis.svg'
import { JsonViewToggle } from '@percent/partner-dashboard/common/components/drawer/JsonViewToggle'
import { EllipsisTableCellProps } from './EllipsisTableCell.types'
import { Icon } from '@percent/lemonade'

export function EllipsisTableCell({ rawJsonData }: EllipsisTableCellProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <TableCell className={styles.ellipsesCell} data-testid="ellipses-table-cell">
      <MenuItem onClick={handleClick}>
        <Typography
          aria-haspopup="true"
          onClick={handleClick}
          className={styles.ellipsisIcon}
          data-testid="open-menu-list-composition"
        >
          <Icon name="menu-horizontal" size={6} />
        </Typography>
      </MenuItem>
      <div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={styles.menu}
        >
          <MenuItem className={styles.menuItem} onClick={handleClose}>
            <JsonViewToggle rawJsonData={rawJsonData} />
          </MenuItem>
        </Menu>
      </div>
    </TableCell>
  )
}
