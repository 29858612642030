import { StatusType } from '@percent/partner-dashboard/constants/status/statusType'
import { ValidationSubmission } from '@percent/partner-dashboard/services/applications/applicationsService.types'

export const mapOrganisationStatus = (
  verification: ValidationSubmission
): StatusType.Pending | StatusType.Approved | StatusType.Rejected => {
  if (verification.validationRequest?.rejectedAt) {
    return StatusType.Rejected
  }

  if (verification?.organisationId) {
    return StatusType.Approved
  }

  return StatusType.Pending
}
