import { useHistory } from 'react-router-dom'
import { Button, Spacer } from '@percent/lemonade'
import ExpiredSessionImage from '../../../../../shared/ui-lemonade/src/assets/images/expired-session.svg'
import styles from './ExpiredSessionHandle.module.scss'
import { CentralCardLayout } from '@percent/domain/iam'
import { ExpiredSessionHandleProps } from './ExpiredSessionHandle.types'

export function ExpiredSessionHandle({
  heading,
  description,
  buttonText,
  redirectRoute,
  footer,
}: ExpiredSessionHandleProps) {
  const history = useHistory()
  const redirectToLogin = () => history.push(redirectRoute)

  return (
    <CentralCardLayout
      CustomHeaderImage={
        <img
          src={ExpiredSessionImage}
          alt="expired session"
          className={styles.sessionImage}
        />
      }
      footer={footer}
    >
      <h5 className={styles.sessionExpiredText}>{heading}</h5>
      <p className={styles.loggedOutText}>{description}</p>
      <Spacer size={5} axis="vertical" />
      <Button stretch size="large" onPress={redirectToLogin}>
        {buttonText}
      </Button>
    </CentralCardLayout>
  )
}
