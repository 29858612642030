import React from 'react'
import { useHistory } from 'react-router-dom'

import { ChangeTwoFactorDevice } from './ChangeTwoFactorDevice'
import { ChangeTwoFactorDeviceContainerProps } from './ChangeTwoFactorDevice.types'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useMutation } from '@percent/partner-dashboard/common/hooks'

export function ChangeTwoFactorDeviceContainer({ isOpened, onClose }: ChangeTwoFactorDeviceContainerProps) {
  const { push } = useHistory()
  const { authService } = useServices()

  const [{ isLoading, errorMessage }, { apiFunc: generateQRToken }] = useMutation(authService.generateQRCode, data => {
    if (data?.data?.data?.otpKey) {
      push('/2fa-setup', { otpData: data?.data?.data })
    }
  })

  return (
    <ChangeTwoFactorDevice
      generateQRToken={generateQRToken}
      errorMessage={errorMessage}
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={onClose}
    />
  )
}
