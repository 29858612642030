import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { SignInWithRecoveryCode } from './SignInWithRecoveryCode'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'
import { SET_AUTHORISED } from '@percent/partner-dashboard/context/auth'
import { useAuthDispatch } from '@percent/partner-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { User } from '@percent/partner-dashboard/context/auth/authContext/AuthContext.types'

export function SignInWithRecoveryCodeContainer() {
  const dispatch = useAuthDispatch()
  const { user } = useAuthState()
  const { push } = useHistory()
  const { authService } = useServices()
  const [userData, setUserData] = useState<undefined | User>(undefined)
  const [isRecoveryCodeConfirmed, setIsRecoveryCodeConfirmed] = useState(false)
  const [{ errorMessage, isLoading, data: response, success }, { apiFunc: signInFunc, setErrorMessage }] = useMutation(
    authService.signInWithRecoveryCode
  )

  const signInToApp = () => {
    setUserData(response?.data.data)

    localStorage.authState = JSON.stringify({
      isAuthorised: true,
      isAuthorising: false
    })

    dispatch({
      type: SET_AUTHORISED,
      payload: {
        user: userData
      }
    })

    push('/')
  }

  useEffect(() => {
    if (success || user?.twoFaBackupCode) {
      setIsRecoveryCodeConfirmed(true)
    }
  }, [success, user])

  return (
    <SignInWithRecoveryCode
      otpCode={response?.data.data.twoFaBackupCode}
      errorMessage={errorMessage}
      isLoading={isLoading}
      signInFunc={signInFunc}
      isRecoveryCodeConfirmed={isRecoveryCodeConfirmed}
      setErrorMessage={setErrorMessage}
      signInToApp={signInToApp}
    />
  )
}
