import { useAuthState } from '@percent/partner-dashboard/common/hooks'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export enum Partner {
  CANVA = 'canva',
  ATLASSIAN = 'atlassian'
}

export const usePartnerMessage = () => {
  const { t } = useTranslation()
  const { partner } = useAuthState()

  const partnerMessage = useMemo(() => {
    switch (partner?.name?.toLowerCase()) {
      case Partner.CANVA:
        return t('verifications.canvaMessage')
      case Partner.ATLASSIAN:
        return t('verifications.atlassianMessage')
      default:
        return undefined
    }
  }, [partner?.name, t])

  return partnerMessage
}
