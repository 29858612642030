import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { SetPassword } from '../setPassword/SetPassword'
import { ForgotPassword } from '../forgotPassword/ForgotPassword'
import { SignIn } from '../signIn/SignIn'
import { SignWithTwoFA } from '../signIn/TwoFactorAuthentication/SignInWithTwoFA/SignWithTwoFA'
import { SignInWithRecoveryCodeContainer } from '../signIn/TwoFactorAuthentication/SignInWithRecoveryCode/SignInWithRecoveryCodeContainer'
import { SSO } from '../sso/SSO'

import { SSOGateway } from '../sso/SSOGateway'
import { ExpiredSessionHandleContainer } from '../expiredSessionHandleContainer/ExpiredSessionHandleContainer'

export function AuthRoutes() {
  return (
    <Switch>
      <Route path="/signin/2fa-recovery">
        <SignInWithRecoveryCodeContainer />
      </Route>
      <Route path="/signin/2fa">
        <SignWithTwoFA />
      </Route>
      <Route path="/signin/sso/gateway">
        <SSOGateway />
      </Route>
      <Route path="/signin/sso">
        <SSO />
      </Route>
      <Route path="/signin">
        <SignIn />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/set-password">
        <SetPassword />
      </Route>
      <Route exact path="/expired-session">
        <ExpiredSessionHandleContainer />
      </Route>
      <Route path="*">
        <Redirect to="/signin" />
      </Route>
    </Switch>
  )
}
