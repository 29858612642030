import { Box, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import React from 'react'

import styles from './PaginationActions.module.scss'
import { PaginationActionsProps } from './PaginationActions.types'
import { useTranslation } from 'react-i18next'

export function PaginationActions({ count, nextPage, previousPage }: PaginationActionsProps) {
  const { t } = useTranslation()

  const handleBackButtonClick = () => previousPage?.()
  const handleNextButtonClick = () => nextPage?.()

  return (
    <Box className={styles.container}>
      <Typography>{t('table.results', { count: count || undefined })}</Typography>
      <div>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={!previousPage}
          aria-label="previous page"
          className={previousPage ? styles.paginationButton : styles.paginationButtonDisabled}
          data-testid="back-button"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={!nextPage}
          aria-label="next page"
          className={nextPage ? styles.paginationButton : styles.paginationButtonDisabled}
          data-testid="next-button"
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    </Box>
  )
}
