import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { BreadcrumbsContextController } from './breadcrumbsContext/breadcrumbsContextController/BreadcrumbsContextController'
import { Routes } from './Routes'
import { TopBar } from './topbar/TopBar'
import { NoPrivilegeAccessView } from '@percent/partner-dashboard/common/components'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { Banner, Logo, Navigation } from '@percent/lemonade'
import { useAuthState, useFeatureFlag } from '@percent/partner-dashboard/common/hooks'
import { selectScopes } from '@percent/partner-dashboard/context/auth'
import { PartnerScopes } from '@percent/partner-dashboard/constants/scopes'
import { NavItemProps } from 'libs/shared/ui-lemonade/src/components/navigation/navigation.types'
import { NavConfigItem, GetPDNavItemsParams, CreateNavItemParams } from './Layout.types'
import styles from './Layout.module.scss'
import classNames from 'classnames/bind'
export const drawerWidth = '240px'

const cx = classNames.bind(styles)

const GOODSTACK_BANNER_CLOSED_KEY = 'pd_goodstack_banner_closed'

const useGoodstackBanner = () => {
  const [shouldShowBanner, setShouldShowBanner] = useState(!localStorage.getItem(GOODSTACK_BANNER_CLOSED_KEY))

  const closeBanner = () => {
    localStorage.setItem(GOODSTACK_BANNER_CLOSED_KEY, 'true')
    setShouldShowBanner(false)
  }

  return { shouldShowBanner, closeBanner }
}

function createNavItem({ condition, icon, label, href, children, pathname }: CreateNavItemParams) {
  if (!condition) return null

  return {
    icon,
    label,
    href,
    isActive: pathname.includes(href.includes('?') ? href.split(/\?/)[0] : href.split(/\/+/).filter(Boolean)[0]),
    ...(children
      ? {
          children: children.map(child => ({ ...child, isActive: child.pathname.includes(child.href.split('?')[0]) }))
        }
      : {})
  }
}

function getPDNavItems({ pathname, scopes, featureFlags, userCan, t }: GetPDNavItemsParams) {
  const navConfig: NavConfigItem[] = [
    {
      icon: 'menu-dashboard',
      label: t('menu.dashboard'),
      href: '/dashboard',
      condition: true
    },
    {
      icon: 'menu-donations',
      label: t('menu.donations'),
      href: '/donations',
      condition: (scopes[PartnerScopes.Donation] || scopes[PartnerScopes.DonationSession]) && userCan('viewDonations')
    },
    {
      icon: 'menu-categories',
      label: t('menu.categories'),
      href: '/categories',
      condition: scopes[PartnerScopes.Organisation]
    },
    {
      icon: 'menu-nonprofits',
      label: t('menu.nonprofits'),
      href: '/nonprofits',
      condition: scopes[PartnerScopes.Donation] && scopes[PartnerScopes.Organisation]
    },
    {
      icon: 'menu-workplace-giving',
      label: t('menu.workplaceGiving'),
      href: '/workplace-giving/employees',
      condition: scopes[PartnerScopes.WorkplaceGiving] && (userCan('manageOwners') || userCan('manageAdmins')),
      children: [
        {
          label: 'Employees',
          href: '/workplace-giving/employees'
        },
        ...(scopes[PartnerScopes.DonationLink] && featureFlags.workplaceGivingFeatureFlag === true
          ? [
              {
                label: 'Fundraisers',
                href: '/workplace-giving/fundraisers'
              }
            ]
          : [])
      ]
    },
    {
      icon: 'menu-verifications',
      label: t('menu.verifications'),
      href: '/verifications',
      condition: scopes[PartnerScopes.ValidationInvite] || scopes[PartnerScopes.SingleValidationSubmissionApi]
    },
    {
      icon: 'menu-keys',
      label: t('menu.keys'),
      href: '/keys',
      condition: userCan('viewApiKeys')
    }
  ]

  return navConfig
    .map(item =>
      createNavItem({
        ...item,
        pathname,
        children: item.children ? item.children.map(child => ({ ...child, pathname })) : undefined
      })
    )
    .filter((item): item is NavItemProps => !!item)
}

export function Layout() {
  const [path, setPath] = useState<string | undefined | null>(undefined)
  const { shouldShowBanner, closeBanner } = useGoodstackBanner()
  const { t } = useTranslation()
  const { workplaceGivingFeatureFlag } = useFeatureFlag()
  const { userCan } = usePermissionCheck()

  const { pathname } = useLocation()
  const { partner } = useAuthState()
  const scopes = selectScopes(partner?.scopes)

  if (userCan('viewDashboard')) {
    return (
      <BreadcrumbsContextController path={path} setPath={setPath}>
        <Banner show={shouldShowBanner} onClose={closeBanner}>
          <p>
            <Trans i18nKey="typography.weAreGoodstack">
              <a href="https://goodstack.io/" target="_blank" rel="noreferrer">
                Goodstack
              </a>
              <a
                href="https://goodstack.io/blog/as-of-today-we-are-excited-to-announce-we-are-rebranding-to-goodstack"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </Trans>
          </p>
        </Banner>
        <TopBar />
        <Navigation
          logo={<Logo className={styles.logo} />}
          items={getPDNavItems({
            pathname,
            scopes,
            featureFlags: {
              workplaceGivingFeatureFlag
            },
            userCan,
            t
          })}
          className={cx({ navigationWithBanner: shouldShowBanner })}
        />
        <div style={{ marginLeft: drawerWidth, padding: 40 }}>
          <Routes />
        </div>
      </BreadcrumbsContextController>
    )
  }

  return <NoPrivilegeAccessView />
}
