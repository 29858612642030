import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import 'react-image-gallery/styles/css/image-gallery.css'

import { ReactComponent as PdfIcon } from '../../assets/images/icons/pdf-icon.svg'
import { GalleryImages, GalleryProps } from './gallery.types'

const galleryFullscreen = css`
  display: block;

  > div:first-child {
    background: white;
  }

  img,
  iframe {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    border-radius: 0px;

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }
  }

  iframe {
    display: block;
    background: #000;
    border: none;
    width: 100%;
    height: 100vh;
  }

  .thumbnailPdf {
    border-radius: 0;
  }
`

const gallery = css`
  display: flex;
  width: 100%;

  button {
    width: 160px;
    height: 160px;
    border: none;

    span {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
    }
  }

  img {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    border-radius: 8px;
    width: 160px;
    height: 160px;
    object-fit: cover;

    &:hover,
    &:focus,
    &:active {
      border: 4px solid ${(p) => p.theme.colors.primary400};
      width: 152px;
      height: 152px;
      cursor: pointer;
    }
  }
`

const GalleryContainer = styled.div`
  .galleryFullscreen {
    ${galleryFullscreen}
  }

  .gallery {
    ${gallery}
  }
`

const thumbnailBase = css`
  position: relative;
  display: inline-block;
  transition: border 0.3s ease-out;
  background: transparent;
  padding: 0;
  outline: none;
  border: none;
`

const thumbnail = css`
  ${thumbnailBase}
  width: 160px;
  height: 160px;

  span {
    display: block;
    position: relative;

    &:hover:after {
      position: absolute;
      right: 4px;
      top: 4px;
      content: '';
      width: 152px;
      height: 152px;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.3);
      pointer-events: none;
      border-radius: 8px;
    }
  }

  img {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
  }

  &:hover:after {
    position: absolute;
    right: 24px;
    bottom: 24px;
    content: url('../assets/images/icons/hover-box.svg');
    width: 10px;
    height: 10px;
    cursor: pointer;
    background: transparent;
    pointer-events: none;
  }
`
const thumbnailFullscreen = css`
  ${thumbnailBase}

  img {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    border-radius: 0px;
    object-fit: cover;
    width: 92px;
    height: 92px;

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }
  }
`

const thumbnailPdf = css`
  ${thumbnailBase}
  background: ${(p) => p.theme.colors.gray80};
  border-radius: 8px;
  width: 92px;
  height: 92px;
  vertical-align: middle;
  border: none;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;

    &:hover:after {
      position: absolute;
      right: 0px;
      top: 0px;
      content: '';
      width: 152px;
      height: 152px;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.3);
      border: 4px solid ${(p) => p.theme.colors.primary400};
      pointer-events: none;
      border-radius: 8px;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:hover:after {
    position: absolute;
    right: 24px;
    bottom: 24px;
    content: url('../assets/images/icons/hover-box.svg');
    width: 10px;
    height: 10px;
    cursor: pointer;
    background: transparent;
    pointer-events: none;
  }
`

const thumbnailPdfFullScreen = css`
  ${thumbnailBase}
  background: ${(p) => p.theme.colors.gray80};
  width: 92px;
  height: 92px;
  vertical-align: middle;
  border: none;

  img {
    vertical-align: middle;
    line-height: 0;
    border-radius: 0px;
    object-fit: cover;
    width: 92px;
    height: 92px;

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }
  }
`

const StyledItemButton = styled.button<{
  isFullscreen: boolean
  isPdf: boolean
}>`
  ${(p) => !p.isFullscreen && !p.isPdf && thumbnail}
  ${(p) => p.isFullscreen && !p.isPdf && thumbnailFullscreen}
  ${(p) =>
    p.isPdf &&
    !p.isFullscreen &&
    css`
      ${thumbnailPdf}
    `}
    ${(p) =>
    p.isPdf &&
    p.isFullscreen &&
    css`
      ${thumbnailPdfFullScreen}
    `}
`

const StyledImageGallery = styled(ImageGallery)`
  ${(p) => (p.showFullscreenButton ? galleryFullscreen : gallery)}
`

const renderPDFItem = (props: ReactImageGalleryItem) => (
  <iframe
    title="validation request document"
    src={props.original}
    id="gallery-image"
    className="image-gallery-image"
  />
)
const renderItem = (
  props: ReactImageGalleryItem & {
    ref: ImageGallery | null
    isFullscreen: boolean
  }
) => {
  const isPdf = props.original.includes('.pdf')
  const handleClick = () => {
    props.ref?.fullScreen()
  }

  return (
    <StyledItemButton
      type="button"
      isFullscreen={props.isFullscreen}
      isPdf={isPdf}
      onClick={handleClick}
    >
      <span>
        {isPdf ? (
          <PdfIcon />
        ) : (
          <img src={props.thumbnail} alt="validation request document" />
        )}
      </span>
    </StyledItemButton>
  )
}

const mapToImages = (documents: GalleryImages) =>
  documents.map((document) => ({
    ...document,
    original: document.url,
    thumbnail: document.url,
    ...(document.url.includes('.pdf') ? { renderItem: renderPDFItem } : {}),
  }))

export const Gallery = ({
  galleryRef,
  images,
  isLoading,
  testId,
}: GalleryProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false)

  useEffect(() => {
    if (document) {
      const gallerySlider = document.getElementsByClassName(
        'image-gallery-slide-wrapper'
      )[0]
      const gallerythumbnails = document.getElementsByClassName(
        'image-gallery-thumbnails-wrapper'
      )[0]
      const galleryImages = document.getElementsByClassName(
        'image-gallery-image'
      )

      if (gallerySlider && gallerythumbnails && galleryImages && isFullscreen) {
        gallerySlider.id = ''
        gallerythumbnails.id = ''

        Array.from(galleryImages).forEach((_, index) => {
          galleryImages[index].id = 'gallery-image'
        })

        testId &&
          gallerySlider.setAttribute('data-testid', `${testId}-fullscreen`)
      }

      if (
        gallerySlider &&
        gallerythumbnails &&
        galleryImages &&
        !isFullscreen
      ) {
        gallerySlider.id = 'gallery-slider'
        testId && gallerySlider.setAttribute('data-testid', testId)

        gallerythumbnails.id = 'gallery-thumbnails'

        Array.from(galleryImages).forEach((_, index) => {
          galleryImages[index].id = ''
        })
      }
    }
  }, [testId, isLoading, isFullscreen])

  const handleScreenChange = (shouldShowFullscreen: boolean) => {
    setIsFullscreen(shouldShowFullscreen)

    if (!shouldShowFullscreen) {
      const gallerythumbnails = document.getElementsByClassName(
        'image-gallery-thumbnails-wrapper'
      )[0]
      gallerythumbnails.scrollIntoView()
    }
  }

  return (
    <GalleryContainer>
      <StyledImageGallery
        ref={galleryRef}
        showNav={isFullscreen}
        showFullscreenButton={isFullscreen}
        additionalClass={isFullscreen ? 'galleryFullscreen' : 'gallery'}
        showPlayButton={false}
        items={mapToImages(images)}
        onScreenChange={handleScreenChange}
        renderThumbInner={(props) =>
          renderItem({ ...props, ref: galleryRef.current, isFullscreen })
        }
      />
    </GalleryContainer>
  )
}
