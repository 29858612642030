import { Box, Grid, Paper } from '@material-ui/core'
import React from 'react'

import { KeyListTable } from './KeyListTable'

export function Key() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box component={Paper}>
          <KeyListTable />
        </Box>
      </Grid>
    </Grid>
  )
}
