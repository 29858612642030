import { CardDashboard, CardContent } from '@percent/lemonade'

import { useTranslation } from 'react-i18next'
import { VerificationMetadataCardProps } from './VerificationMetadataCard.types'

export const VerificationMetadataCard = ({ metadata }: VerificationMetadataCardProps) => {
  const { t } = useTranslation()

  return (
    <CardDashboard title={t('typography.metadata')}>
      {Object.entries(metadata).map(([key, value], index) => (
        <CardContent key={key} title={key} testId={`verification-metadata-${index}`}>
          <span>{value || '-'}</span>
        </CardContent>
      ))}
    </CardDashboard>
  )
}
