import React from 'react'
import { TableContainer, Toolbar, Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import styles from './KeyListTable.module.scss'
import { KeyList } from './KeyList'
import { KeyListNoAccess } from './KeyListNoAccess'

export function KeyListTable() {
  const { t } = useTranslation()

  const { userCan } = usePermissionCheck()

  return (
    <TableContainer>
      <Toolbar className={styles.keysHeader}>
        <Typography variant="h6" color="secondary">
          {t('table.header.keys')}
        </Typography>
      </Toolbar>
      {userCan('viewApiKeys') ? <KeyList /> : <KeyListNoAccess />}
    </TableContainer>
  )
}
