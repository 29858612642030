import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import utc from 'dayjs/plugin/utc'

dayjs.extend(localizedFormat)
dayjs.extend(dayOfYear)
dayjs.extend(utc)

export { dayjs as dayJS }

export const getFormattedDate = (date?: string) => {
  return dayjs(date).format('D MMM YYYY, HH:mm')
}
