import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { ErrorView } from '@percent/partner-dashboard/common/components/errorView/ErrorView'
import styles from './CategoryTable.module.scss'
import { useTranslation } from 'react-i18next'
import { CategoryTableProps, CategoryTableItemTypes } from './CategoryTable.types'
import { ReactComponent as RightArrowIcon } from '@percent/partner-dashboard/common/assets/images/right-arrow-icon.svg'
import { Table } from '@percent/partner-dashboard/common/components'
import { TableCellEllipsisValue } from '@percent/partner-dashboard/common/components/tableCellEllipsisValue/TableCellEllipsisValue'
import { Copy } from '@percent/lemonade'

export function CategoryTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage
}: CategoryTableProps) {
  const { t } = useTranslation()
  const history = useHistory()

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  const rowCategoryClicked = (categoryId: string) => history.push(`/categories/${categoryId}`)

  const columns = [
    { id: 'id', isSortable: false },
    { id: 'name', isSortable: false, props: { align: 'right' } }
  ]

  return (
    <Table
      data={data}
      title={t('menu.categories')}
      isLoading={isLoading}
      totalResults={totalResults}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={columns}
      emptyTableText={t('typography.noCategoryTable')}
      emptyTableImageType="tick"
      handleRequestSort={() => {
        // do nothing
      }}
      orderBy=""
    >
      {data?.map(({ id, name }: CategoryTableItemTypes) => (
        <TableRow key={`donation-list-${id}`}>
          <TableCell>
            <Copy message={t('typography.copyId')} text={id}>
              <TableCellEllipsisValue id={id} ellipsis={false} />
            </Copy>
          </TableCell>
          <TableCell align="right">{name}</TableCell>
          <TableCell
            align="right"
            className={styles.rowCategoryArrow}
            onClick={() => rowCategoryClicked(id)}
            data-testid={`${name}-category-clicked`}
          >
            <RightArrowIcon />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
