import { StatusType } from '@percent/partner-dashboard/constants/status/statusType'

const alertProps = () => ({
  approved: {
    variant: 'success'
  },
  rejected: {
    variant: 'error'
  },
  pending: {
    variant: 'info'
  }
})

export const mapAlertProps = (status: StatusType) => alertProps()[status]
