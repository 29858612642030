import React from 'react'

import { useTranslation } from 'react-i18next'
import styles from './KeyList.module.scss'

export function KeyListNoAccess() {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.title}>{t('typography.noAccess')}</div>
        {t('typography.noAccessDescription')}
      </div>
    </div>
  )
}
