import { AnimatedContainer, Button, Spacer } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { SetupCompleteProps } from './SetupComplete.types'
import { ReactComponent as SetupCompleteIcon } from '@percent/partner-dashboard/common/assets/images/success-icon.svg'
import styles from './SetupComplete.module.scss'
import { AuthForm } from '../../../AuthForm'

export function SetupComplete({ signInToApp }: SetupCompleteProps) {
  const { t } = useTranslation()

  return (
    <AuthForm
      heading={t('2fa.completeHeader')}
      customHeaderImage={
        <AnimatedContainer>
          <SetupCompleteIcon />
        </AnimatedContainer>
      }
    >
      <p className={styles.subtext}>{t('2fa.completeSubtext')}</p>
      <Spacer size={4} axis="vertical" />
      <Button onPress={signInToApp} size="large" stretch>
        {t('button.done')}
      </Button>
    </AuthForm>
  )
}
