import { Badge, Spacer } from '@percent/lemonade'
import { getOrgTypes, mapOrganisationTypes } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { OrgansiationTypesBadgesProps } from './OrganisationTypesBadges.types'
import { Fragment } from 'react'

export const OrganisationTypesBadges = ({
  organisation,
  validationRequest,
  organisationId
}: OrgansiationTypesBadgesProps) => {
  const { t } = useTranslation()

  const organisationTypes = organisation?.types || validationRequest?.organisationTypes

  return (
    <div>
      {getOrgTypes({
        organisationTypes,
        organisationId,
        rejectedAt: validationRequest?.rejectedAt
      }).map(orgType => (
        <Fragment key={orgType}>
          <Badge
            variant={mapOrganisationTypes(orgType)?.status}
            icon={mapOrganisationTypes(orgType)?.icon}
            key={`org-types-${validationRequest?.id}`}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {t(mapOrganisationTypes(orgType)?.text as any)}
          </Badge>
          <Spacer size={1} axis="horizontal" />
        </Fragment>
      ))}
    </div>
  )
}
