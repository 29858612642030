import { useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { StringParam, useQueryParams } from 'use-query-params'
import { EmployeesTable } from './EmployeesTable/EmployeesTable'

export function EmployeesContainer() {
  const { workplaceGivingService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams(
    {
      filter: StringParam
    },
    {}
  )

  const [{ data, isLoading, errorMessage, totalResults }, { query, nextPage, previousPage, refresh }] = useQueryList(
    workplaceGivingService.getEmployeesList,
    queryParams
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <EmployeesTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
