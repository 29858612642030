import styled, { css } from 'styled-components'
import { Icon, IconRegistry } from '../icon'

const IconLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  width: ${({ theme }) => theme.sizes.l};
  display: flex;
  align-items: center;
  transition: all ${({ theme }) => theme.transitionTime} ease;
`

const LinkLabel = styled.span`
  ${({ theme }) => css`
    transition: all ${theme.transitionTime} ease;
    border-bottom: 1px solid transparent;
  `}
`

const ChevronContainer = styled.div<{ $isOpen: boolean }>`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    transition: all ${theme.transitionTime} ease;
    opacity: 1;
    margin-left: ${theme.sizes.xs};
  `}
`

const StyledMainLink = styled.div<{ $isActive: boolean; $isSubItem: boolean }>`
  ${({ theme, $isActive, $isSubItem }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    text-decoration: none;
    transition: all ${theme.transitionTime} ease;
    cursor: pointer;
    color: ${theme.colors.gray700};

    ${IconContainer} path {
      fill: ${theme.colors.gray700};
    }

    &:link,
    &:visited {
      color: ${theme.colors.gray700};
    }

    &:hover,
    &:active {
      color: ${theme.colors.black};

      ${IconContainer} path {
        fill: ${theme.colors.primary400};
      }

      ${LinkLabel} {
        border-color: ${theme.colors.primary400};
      }

      ${ChevronContainer} {
        opacity: 1;
      }
    }

    &:active {
      color: ${theme.colors.black};

      ${LinkLabel} {
        border-color: transparent;
      }
    }

    ${$isActive &&
    css`
      color: ${theme.colors[$isSubItem ? 'primary400' : 'black']};

      ${IconContainer} path {
        fill: ${theme.colors.primary400};
      }

      ${LinkLabel} {
        border-color: transparent;
      }
    `}
  `}
`

interface NavItemProps {
  label: string
  isActive: boolean
  icon?: keyof typeof IconRegistry
  onClick?: VoidFunction
  hasChevron?: boolean
  isSubItem?: boolean
}

export const NavItem = ({
  label,
  isActive,
  icon,
  onClick,
  hasChevron,
  isSubItem = false,
}: NavItemProps) => {
  return (
    <StyledMainLink
      $isActive={isActive}
      $isSubItem={isSubItem}
      onClick={onClick}
    >
      <IconLabelWrapper>
        {icon ? (
          <IconContainer>
            <Icon name={icon} size={5} />
          </IconContainer>
        ) : null}
        <LinkLabel>{label}</LinkLabel>
      </IconLabelWrapper>
      {hasChevron ? (
        <ChevronContainer $isOpen={isActive}>
          <Icon name={'chevron-down'} size="s" color="gray400" />
        </ChevronContainer>
      ) : null}
    </StyledMainLink>
  )
}
