import { useContext, useMemo } from 'react'
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'

import {
  splitSdkConfig,
  Feature,
  features,
  featureNames
} from '@percent/partner-dashboard/app/providers/SplitSdkProvider/SplitSdkProvider'
import { useAuthState } from '../useAuthState/useAuthState'

export function useFeatureFlag() {
  const { partner } = useAuthState()
  const treatments = useTreatments(Object.keys(splitSdkConfig.features), {
    partner_id: partner?.id
  })

  const { isReady, isTimedout } = useContext(SplitContext)

  const featureFlagValue = useMemo(() => {
    const featureFlags = featureNames.reduce((acc, feature) => {
      acc[feature] =
        (isReady && treatments[features[feature].key].treatment === 'on') ||
        (isTimedout && features[feature].defaultValue === 'on')

      return acc
    }, {} as Record<Feature, boolean>)

    return { ...featureFlags, _meta: { isReady } }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isTimedout])

  return featureFlagValue
}
