import React from 'react'
import { Grid } from '@material-ui/core'
import { Icon } from '@percent/lemonade'
import { ModalHeaderProps } from '../Modal.types'
import styles from './ModalHeader.module.scss'

export function ModalHeader({ title, onClose }: ModalHeaderProps) {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <span className={styles.title}>{title}</span>
      <div className={styles.closeDialog} onClick={onClose}>
        <Icon name="close" size={6} />
      </div>
      <div className={styles.divider}></div>
    </Grid>
  )
}
