import React from 'react'

import { capabilities } from '@percent/partner-dashboard/capabilities'
import { services } from '@percent/partner-dashboard/services'
import { ServiceContextController } from './ServiceContextController'
import { ServiceProviderProps } from './ServiceContext.types'

const appServices = services(capabilities)

export function ServiceContextProvider({ children }: ServiceProviderProps) {
  return <ServiceContextController services={appServices}>{children}</ServiceContextController>
}
