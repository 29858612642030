import { createContext, useContext } from 'react'

import { Severity } from './Toast.types'

export const ToastContext = createContext<undefined | ((_: string, __: Severity) => void)>(undefined)

export const useToast = () => {
  const ctx = useContext(ToastContext) as (_: string, __: Severity) => void

  return ctx
}
