import { useTranslation } from 'react-i18next'
import { VerificationsProps } from '../VerificationsTable.types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { EventHandler, KeyboardEvent, useCallback } from 'react'

const emailRegex =
  // eslint-disable-next-line no-control-regex, no-useless-escape
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/

export const useAgentEmailSearch = ({
  queryParams,
  setQueryParams
}: Pick<VerificationsProps, 'queryParams' | 'setQueryParams'>) => {
  const { t } = useTranslation()

  const { errors, values, setFieldValue, handleChange, submitForm, validateForm } = useFormik({
    initialValues: {
      email: queryParams.email
    },
    validateOnChange: false,
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string()
          .trim()
          .matches(emailRegex, t('errorMessage.invalidEmail'))
          .max(255, t('errorMessage.emailMaxLength', { max: 255 }))
      }),
    onSubmit: ({ email }) => setQueryParams({ email: email?.trim() || undefined })
  })

  const handleClearEmailValue = () => {
    setFieldValue('email', '')
    submitForm()
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        submitForm()
      }

      if (event.key === 'Escape') {
        setFieldValue('email', '')
      }

      return null
    },
    [setFieldValue, submitForm]
  )

  return {
    email: values.email,
    onEmailChange: handleChange,
    handleClearEmailValue,
    handleKeyPress,
    validateEmail: () => validateForm(),
    emailError: `${errors.email ?? ''}`
  }
}
