import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Layout } from '../dashboard/layout/Layout'

import { AuthorisedRoute } from '@percent/partner-dashboard/app/auth/authorisedRoute/AuthorisedRoute'
import { TwoFactorAuthenticationSetup } from '@percent/partner-dashboard/app/auth/signIn/TwoFactorAuthentication/TwoFactorAuthenticationSetup'
import { EnvironmentOverlay } from '../dashboard/layout/environmentOverlay/EnvironmentOverlay'

export function AppRoutes() {
  return (
    <>
      <EnvironmentOverlay />
      <Switch>
        <Route component={TwoFactorAuthenticationSetup} exact path="/2fa-setup" />
        <Route>
          <AuthorisedRoute path="/" component={Layout} />
        </Route>
      </Switch>
    </>
  )
}
