import { AxiosInstance } from 'axios'

import { CategoryTableItemTypes } from '../../app/dashboard/category/CategoryTable/CategoryTable.types'

import { apiPaths } from '@percent/partner-dashboard/services/apiPaths'
import { PBPListResponse, PBPGetResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'
import {
  CategoryNonprofitsTableItemTypes,
  GetCategoryListProps,
  GetCategoryNonprofitsListProps,
  GetCategoryProps,
  GetCategoryTypes
} from './category.types'

type CategoryServiceCapabilities = {
  percentClient: AxiosInstance
}

export const categoryService = ({ percentClient }: CategoryServiceCapabilities) => ({
  getCategoryList: (params: GetCategoryListProps) =>
    percentClient.get<PBPListResponse<CategoryTableItemTypes>>(`${apiPaths.v1Category}`, { params }),
  getCategoryNonprofitsList: (params: GetCategoryNonprofitsListProps) =>
    percentClient.get<PBPListResponse<CategoryNonprofitsTableItemTypes>>(`${apiPaths.v1Nonprofits}`, {
      params
    }),
  getCategory: (params: GetCategoryProps) =>
    percentClient.get<PBPGetResponse<GetCategoryTypes>>(`${apiPaths.v1Category}/${params.id}`)
})
