import React from 'react'
import styled from 'styled-components'
import { ModalFooterProps, StyledModalFooterProps } from './modal-footer.types'

export const StyledModalFooter = styled.div<StyledModalFooterProps>`
  ${({ theme, $justify }) =>
    `
    display: flex;
    justify-content: ${$justify};
    padding: ${theme.sizes[6]};
    gap: ${theme.sizes[4]};

    ::after {
      content: '';
      position: absolute;
      width: calc(100% - 2 * ${theme.sizes[6]});
      height: 1px;
      background: ${theme.colors.gray80};
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
    }
  `}
`
export const ModalFooter = ({
  justify = 'flex-end',
  children,
}: ModalFooterProps) => {
  return <StyledModalFooter $justify={justify}>{children}</StyledModalFooter>
}
