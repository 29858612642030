import React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { TableHeadSorterProps } from './TableHeadSorter.types'
import { LocaleKey } from '@percent/partner-dashboard/i18n/config'

export function TableHeadSorter({ onRequestSort, order, orderBy, cells, testId }: TableHeadSorterProps) {
  const { t } = useTranslation()
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort?.(event, property)
  }

  return (
    <TableHead data-testid={testId}>
      <TableRow>
        {cells.map(cell => {
          const headerCell = cell.id.substring(cell.id.indexOf('.') + 1)
          return cell.isSortable ? (
            <TableCell
              {...cell.props}
              style={cell.style}
              key={headerCell}
              sortDirection={orderBy === headerCell ? order : false}
            >
              <TableSortLabel
                active={orderBy === headerCell}
                direction={orderBy === headerCell ? order : 'asc'}
                onClick={createSortHandler(headerCell)}
              >
                {t(`table.${cell.id}` as LocaleKey)}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell {...cell.props} style={cell.style} key={headerCell}>
              {t(`table.${cell.id}` as LocaleKey)}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
