export const getQueryParamsFromUrl = <T extends Record<string, string | string[]>>(apiUrl: string): T => {
  const url = new URL(apiUrl)
  const searchParams = new URLSearchParams(url.search)

  return Array.from(searchParams.entries()).reduce<T>((queryParams, [key, value]) => {
    if (!queryParams[key]) {
      return { ...queryParams, [key]: value }
    } else {
      return {
        ...queryParams,
        [key]: Array.isArray(queryParams[key]) ? [...queryParams[key], value] : [queryParams[key] as string, value]
      }
    }
  }, {} as T)
}

export const fileNameFunc = (start: string, end: string) => {
  return `donation_report_start_${start}_end_${end}`
}

export const replaceDashWithEmptySpace = (str: string) => str.replace('-', ' ')

export const inMiddleEllipsis = (str: string): string => {
  if (str.length > 35) {
    return `${str.substr(0, 20)}...${str.substr(str.length - 10, str.length)}`
  }

  return str
}

export const kebabCase = string =>
  string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
