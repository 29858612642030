import { Badge } from '@percent/lemonade'
import { TableCellStatusIconProps } from './TableCellStatusIcon.types'
import { statusIconMap } from './statusIconMap'
import { useTranslation } from 'react-i18next'
import { LocaleKey } from '@percent/partner-dashboard/i18n/config'

export const getProperCasedStatus = (status: string, additionalText?: string) =>
  additionalText ? `${additionalText} ${status.toLowerCase()}` : status

export function TableCellStatusIcon({ statusType, additionalText }: Readonly<TableCellStatusIconProps>) {
  const { t } = useTranslation()

  if (statusIconMap[statusType]) {
    return (
      <Badge variant={statusIconMap[statusType].status} icon={statusIconMap[statusType].icon}>
        {getProperCasedStatus(t(statusIconMap[statusType].text as LocaleKey), additionalText)}
      </Badge>
    )
  }

  return (
    <Badge variant="default" icon="delete">
      {t('status.notAvailable')}
    </Badge>
  )
}
