import { Badge, IconRegistry } from '@percent/lemonade'
import { getProperCasedStatus } from '@percent/partner-dashboard/common/components/table/tableCellStatusIcon/TableCellStatusIcon'
import { LocaleKey } from '@percent/partner-dashboard/i18n/config'
import { DonationListStatusFilterEnum } from '@percent/partner-dashboard/services/donation/donationService.types'
import { BadgeVariant } from 'libs/shared/ui-lemonade/src/components/badge/badge.types'
import { useTranslation } from 'react-i18next'

const donationStatusMap: Record<
  DonationListStatusFilterEnum,
  {
    status: BadgeVariant
    icon: keyof typeof IconRegistry
    text: string
  }
> = {
  [DonationListStatusFilterEnum.Active]: {
    status: 'informative',
    icon: 'info',
    text: 'status.awaitingPayment'
  },
  [DonationListStatusFilterEnum.AwaitingPayment]: {
    status: 'informative',
    icon: 'info',
    text: 'status.awaitingPayment'
  },
  [DonationListStatusFilterEnum.RequestedPayment]: {
    status: 'informative',
    icon: 'info',
    text: 'status.awaitingPayment'
  },
  [DonationListStatusFilterEnum.ReceivedPayment]: {
    status: 'positive',
    icon: 'approve',
    text: 'status.receivedPayment'
  },
  [DonationListStatusFilterEnum.Settled]: {
    status: 'positive',
    icon: 'approve',
    text: 'status.receivedPayment'
  },
  [DonationListStatusFilterEnum.Disbursed]: {
    status: 'positive',
    icon: 'check-badge',
    text: 'status.disbursed'
  },
  [DonationListStatusFilterEnum.Cancelled]: {
    status: 'critical',
    icon: 'reject',
    text: 'status.cancelled'
  }
}

export function DonationStatusTableCell({ status }: { status: DonationListStatusFilterEnum }) {
  const { t: translate } = useTranslation()

  return (
    <Badge variant={donationStatusMap[status].status} icon={donationStatusMap[status].icon}>
      {getProperCasedStatus(translate(donationStatusMap[status].text as LocaleKey))}
    </Badge>
  )
}
