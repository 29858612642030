import { Action } from './authReducer.types'
import { AuthStateContextType, ScopeType } from '@percent/partner-dashboard/context/auth/authContext/AuthContext.types'

export const SET_AUTHORISED = 'auth/set-authorised'
export const SET_UNAUTHORISED = 'auth/set-unauthorised'
export const START_AUTHORISING = 'auth/start-authorising'
export const SET_LOGOUT = 'auth/set-logout'
export const SET_VERIFY = 'auth/set-verify'

export const initialState = {
  isAuthorised: false,
  isAuthorising: true,
  user: undefined,
  partner: undefined,
  currencyInfo: undefined
}

export const initializer = (initialValue = initialState) =>
  JSON.parse(localStorage.getItem('authState')!) || initialValue

export const authReducer: (state: AuthStateContextType, action: Action) => AuthStateContextType = (state, action) => {
  switch (action.type) {
    case START_AUTHORISING:
      return {
        ...state,
        isAuthorising: true
      }
    case SET_AUTHORISED:
      return {
        ...state,
        ...action.payload,
        isAuthorising: false,
        isAuthorised: true
      }
    case SET_UNAUTHORISED:
    case SET_LOGOUT:
    case SET_VERIFY:
      return {
        ...initialState,
        isAuthorising: false,
        isAuthorised: false,
        user: undefined,
        partner: undefined,
        currencyInfo: undefined
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const selectScopes = (scopes?: ScopeType[]) =>
  scopes
    ?.filter(scope => !scope.deletedAt)
    .reduce(
      (obj, { scopeName }) => ({
        ...obj,
        [scopeName]: true as const
      }),
      {} as { [k: string]: true }
    ) || {}
