import { baseUrl } from '@percent/partner-dashboard/services/apiPaths'
import { ReportingServiceCapabilities, GetDonationReportType } from './reportingService.types'

export const reportingService = ({ percentClient }: ReportingServiceCapabilities) => ({
  getDonationReport: ({ start, end, status }: GetDonationReportType): Promise<string> =>
    percentClient
      .get(`${baseUrl}reporting/donations`, {
        params: {
          start,
          end,
          status
        },
        responseType: 'blob'
      })
      .then(({ data }) => data)
})
