import React from 'react'
import { CircularProgress } from '@material-ui/core'

import styles from './Loader.module.scss'
import { LoaderProps } from './Loader.types'

export function Loader({ inline }: LoaderProps) {
  return (
    <div className={inline ? styles.inline : styles.loader}>
      <CircularProgress color="secondary" thickness={5} data-testid="loading" />
    </div>
  )
}
