import { useState } from 'react'

import { useToast } from '@percent/partner-dashboard/containers/toast/ToastContext'
import { APIErrorHandler } from '@percent/partner-dashboard/common/library/APIErrorHandler'
import { FetchDonationCSVType, UseCSVDownloaderPropsType } from './useCSVDownloader.types'
import { dayJS } from '@percent/partner-dashboard/common/library/utility/date'
import { DonationListStatusFilterEnum } from '@percent/partner-dashboard/services/donation/donationService.types'

export const useCSVDownloader = ({ service, fileName, successMessage }: UseCSVDownloaderPropsType) => {
  const [loading, setLoading] = useState(false)
  const [errorCSVMessage, setErrorCSVMessage] = useState('')
  const addToast = useToast()

  const downloadCsv = (data: string, fileN: string): void => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileN}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const fetchDonationCSV = async (csvParamData: FetchDonationCSVType) => {
    setLoading(true)
    setErrorCSVMessage('')
    try {
      const start = dayJS(csvParamData?.startDate).format()
      const end = dayJS().isSame(dayJS(csvParamData?.endDate), 'd')
        ? dayJS().utc().format()
        : dayJS(csvParamData?.endDate).utc().endOf('day').format()
      const csvData = await service({
        start,
        end,
        ...(csvParamData?.status && {
          status: csvParamData?.status.filter((s): s is DonationListStatusFilterEnum => Boolean(s))
        })
      })
      downloadCsv(csvData, fileName(start, end))
      addToast(successMessage, 'success')
    } catch (err) {
      setErrorCSVMessage(APIErrorHandler(err?.response?.data?.error))
    } finally {
      setLoading(false)
    }
  }

  return [
    {
      loading,
      errorCSVMessage
    },
    {
      fetchDonationCSV
    }
  ]
}
