import React from 'react'
import { Container, Dialog } from '@material-ui/core'

import { Spacer } from '@percent/lemonade'
import { ModalHeader } from './ModalHeader/ModalHeader'
import { ModalContainerProps } from './Modal.types'

export function Modal({ children, fullscreen, title, openModal, onClose }: ModalContainerProps) {
  return (
    <Dialog
      fullWidth
      open={openModal}
      maxWidth={fullscreen ? 'lg' : 'sm'}
      onClose={onClose}
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      <Container>
        {title && <ModalHeader onClose={onClose} title={title} />}
        {children}
        <Spacer size={5} axis="vertical" />
      </Container>
    </Dialog>
  )
}
