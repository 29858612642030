import styled from 'styled-components'
import { Icon } from '../../icon'
import { useTableContext } from '../context/table-provider'
import { PaginationActionsProps } from '../table.types'

const StyledTotalResults = styled.p`
  ${({ theme }) =>
    `
      display: inline-block;
      font-size: ${theme.fontSizes.bodySmall};
    `}
`

export const PaginationArrow = styled.button`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    text-align: inherit;
    outline: none;
    cursor: pointer;
    &:disabled {
      color: ${theme.colors.gray100};
      border-color: ${theme.colors.gray100};
    };
  `}
`

export const PaginationArrowsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.sizes.xs};
  `}
`

export const PaginationContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.sizes.xs};
    justify-content: space-between;

  `}
`

export function TablePagination({
  previousPage,
  nextPage,
  totalResultsText,
}: PaginationActionsProps) {
  const { totalResults, isLoading } = useTableContext()

  if (totalResults) {
    return (
      <PaginationContainer>
        <StyledTotalResults>{totalResultsText}</StyledTotalResults>
        <PaginationArrowsWrapper>
          <PaginationArrow
            disabled={!previousPage || isLoading}
            onClick={() => previousPage?.()}
            aria-label="previous page"
            data-testid="back-button"
          >
            <Icon name="chevron-left" size={4} />
          </PaginationArrow>
          <PaginationArrow
            disabled={!nextPage || isLoading}
            onClick={() => nextPage?.()}
            aria-label="next page"
            data-testid="next-button"
          >
            <Icon name="chevron-right" size={4} />
          </PaginationArrow>
        </PaginationArrowsWrapper>
      </PaginationContainer>
    )
  }

  return null
}
