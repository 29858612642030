import { Menu } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { EmployeesActionsMenuProps } from './EmployeesActionMenu.types'

export function EmployeesActionsMenu({ handleSelect, items, currency }: EmployeesActionsMenuProps) {
  const { t } = useTranslation()
  return (
    <Menu
      title={t('button.more')}
      disabled={!currency}
      disabledTooltipMessage={t('toolTip.workplaceGiving.noAction')}
      onSelect={key => handleSelect(key)}
      sections={[{ items }]}
    />
  )
}
