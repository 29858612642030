import { StatusType } from '@percent/partner-dashboard/constants/status/statusType'
import { ValidationSubmissionStatus } from '@percent/partner-dashboard/services/applications/applicationsService.types'

export const mapOverallStatus = (
  status: ValidationSubmissionStatus
): StatusType.Pending | StatusType.Approved | StatusType.Rejected => {
  if (status === StatusType.Failed) {
    return StatusType.Rejected
  }

  if (status === StatusType.Succeeded) {
    return StatusType.Approved
  }

  return StatusType.Pending
}
